/*
 * 작성자:
 * 작성일: 241029
 * 설명: Category Num 21
 * 부모 연결:
 * 자식 연결:
 */

// import "../../asset/css/page/InquireReqCourse.css"; // Main:: 문의 css

import React, { useState, useEffect } from "react";
import { useAuth } from "../../asset/util/authContext";
import Pagination from "react-js-pagination";
import manageBoard from "../../asset/util/manageBoard";
import { DivPost1 } from "../component/DivPost1";
import { useModal } from "../../asset/util/useModal";
import { ModalPost_Add } from "../component/ModalPost_Add"; // Modal 컴포넌트
import { ModalPost_View } from "../component/ModalPost_View"; // Modal 컴포넌트

function InquireReqCourse() {
  const [isPost, setPost] = useState([]); // 읽어들은 데이터의 row 정보
  const [selectedPost, setSelectedPost] = useState(null); // 선택된 post
  const [postPerPage] = useState(5); // 페이지당 보여줄 게시글 수
  const [isPage, setPage] = useState(1); // 현재 페이지
  const [isPostCnt, setPostCnt] = useState(0); // 읽어들인 데이터 개수
  const { isSign, userId, userLevel } = useAuth();

  // 모달 상태 관리
  const {
    isModalOpen: isAddModalOpen,
    openModal: openAddModal,
    closeModal: closeAddModal,
  } = useModal(); // 게시글 추가
  const {
    isModalOpen: isViewModalOpen,
    openModal: openViewModal,
    closeModal: closeViewModal,
  } = useModal(); // 게시글 조회

  const getPost = async () => {
    const condition = { category: 21 };
    try {
      const result = await manageBoard({
        type: "select",
        data: condition,
      });
      setPostCnt(result.rowCount);
      setPost(result.rows.reverse()); // 데이터 최신순 정렬
    } catch (error) {
      console.error("게시글을 가져오는 중 오류 발생:", error);
    }
  };

  const getPostPerPage = () => {
    const lastPost = isPage * postPerPage;
    const firstPost = lastPost - postPerPage;
    return isPost.slice(firstPost, lastPost);
  };

  const handlePaging = (pageNum) => {
    setPage(pageNum);
  };

  useEffect(() => {
    getPost();
  }, []);

  // 게시물 클릭 시 모달 열기
  const handlePostClick = (post) => {
    setSelectedPost(post);
    openViewModal();
  };

  return (
    <div className="InquireReqCourse">
      <div className="inquire__top">
        {isSign && userLevel < 5 ? (
          <div className="inquire__notice">
            <h1 className="inquire__notice-title"> 경 로 요 청 (관리자) </h1>
          </div>
        ) : (
          <div className="inquire__notice">
            <h1 className="inquire__notice-title"> 경 로 요 청 </h1>
          </div>
        )}
      </div>
      <div className="inquire__bottom">
        <div className="inquire__board">
          <div id="board_write" className="inquire__board-write">
            <button
              id="board_submit"
              className="inquire__board-submit"
              onClick={openAddModal}
            >
              등록
            </button>
          </div>
          <div id="board_list" className="inquire__board-list">
            <DivPost1 props={getPostPerPage()} onClick={handlePostClick} />
            <div id="list_page" className="inquire__pagination">
              <Pagination
                totalItemsCount={isPostCnt}
                onChange={handlePaging}
                activePage={isPage}
                itemsCountPerPage={postPerPage}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalPost_Add
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        category={21}
      />
      {selectedPost && (
        <ModalPost_View
          isOpen={isViewModalOpen}
          onClose={closeViewModal}
          post={selectedPost}
        />
      )}
    </div>
  );
}

export default InquireReqCourse;
