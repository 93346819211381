/*
 * 작성자:
 * 작성일:
 * 설명:
 * 부모 연결:
 * 자식 연결:
 */
import React from "react";
import { format } from "date-fns";
import "../../asset/css/component/DivPost1.css"; // Utility :: DivPost1 css

export const DivPost1 = ({ props, onClick }) => {
  return (
    <div className="DivPost1">
      <ul className="div-post1__list">
        {props.map((post) => (
          <li
            key={post.post_id}
            className="div-post1__wrap"
            onClick={() => onClick(post)}
          >
            <div className="div-post1__header">
              <div className="div-post1__title">{post.title}</div>
            </div>

            <div className="div-post1__info">
              <span className="div-post1__user">
                ID: {post.user_id || "익명"}
              </span>
              <span className="div-post1__date div-post1__date--create">
                등록일: {format(new Date(post.created_at), "yyyy-MM-dd")}
              </span>
              <span className="div-post1__date div-post1__date--update">
                수정일: {format(new Date(post.updated_at), "yyyy-MM-dd")}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
