import React from "react";
import axios from "axios";
import { FaFileDownload } from "react-icons/fa";
import "../../asset/css/tool/fileList.css";

const FileList = ({ files }) => {
  // 파일이 없을 때 처리
  if (!files || files.length === 0) {
    return (
      <div className="file-list__section">
        <h4 className="file-list__title">첨부 파일:</h4>
        <ul className="file-list__items">
          <li className="file-list__item file-list__item--empty">
            첨부된 파일이 없습니다.
          </li>
        </ul>
      </div>
    );
  }

  // 파일 다운로드 함수
  const handleDownloadFile = (fileItem) => {
    axios
      .get("/file/download/", {
        params: {
          fileName: fileItem.file_path,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileItem.file_name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("파일 다운로드 오류:", error);
      });
  };

  return (
    <div className="FileList">
      <h4 className="file-list__title">첨부 파일:</h4>
      <ul className="file-list__items">
        {files.map((fileItem) => (
          <li key={fileItem.file_id} className="file-list__item">
            <a
              href="#"
              onClick={() => handleDownloadFile(fileItem)}
              className="file-list__link"
            >
              {fileItem.file_name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;
