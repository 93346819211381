/*
 * 작성자: 박준우
 * 작성일: 240910
 * 설명: 댓글 통신용 util
 * 부모 연결: 
 * 자식 연결: 
*/
import axios from "axios";

// GET 요청 처리 함수
const selectComment = (data) => {
    return axios
      .get("comment", { params: data })
      .then((response) => {
        console.log("manageComment :: selec", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Comment GET Error:", error);
        throw error;
      })
      .finally(() => {});
  };
  
  // POST 요청 처리 함수
  const insertComment = (data) => {
    return axios
      .post("comment", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Comment POST Error:", error);
        throw error;
      })
      .finally(() => {});
  };
  
  // PUT 요청 처리 함수
  const updateComment = (data) => {
    return axios
      .put("comment", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Comment PUT Error:", error);
        throw error;
      })
      .finally(() => {});
  };
  
  // DELETE 요청 처리 함수
  const deleteComment = (data) => {
    return axios
      .delete("comment", { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Comment DELETE Error:", error);
        throw error;
      })
      .finally(() => {});
  };

const manageComment = ({ type, data }) => {
    try {
      if (type === "select") {
        return selectComment(data);
      }
  
      if (type === "insert") {
        return insertComment(data);
      }
  
      if (type === "update") {
        return updateComment(data);
      }
      if (type === "delete") {
        return deleteComment(data);
      }
    } catch (error) {
      console.error("manageComment Error:", error);
      throw error; // 오류 발생 시 manageBoard 자체가 실패하도록 함
    }
  };

export default manageComment;