/* ModalSignConfig.js
 * 작성자: 박준우
 * 작성일: 241115
 * 설명: 회원 정보 변경
 * 부모 연결:
 * 자식 연결:
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { compareKey } from "../../asset/util/generateKey";
import { useAuth } from "../../asset/util/authContext";
import authInterceptor from "../../asset/util/authIntercepter";

export const ModalSignConfig = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useAuth();
  const [newUsername, setNewUsername] = useState("");
  const [newNickname, setNewNickname] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    if (!isOpen) return; // 모달 열려 있을 때만 실행

    const checkAuth = async () => {
      const params = new URLSearchParams(location.search);
      const key = params.get("key");

      if (!key) {
        navigate("/Mypage");
        alert("잘못된 접근, redirected to Mypage.");
        return;
      }

      const isKeyMatched = await compareKey(userId, key);
      if (!isKeyMatched) {
        navigate("/Mypage");
        alert("잘못된 접근, redirected to Mypage.");
        return;
      }
    };
    checkAuth();
  }, [isOpen]); // 첫 한 번만 실행할 것 이므로 의존성 없습니다.

  if (!isOpen) return null;

  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleSaveChanges = async () => {
    if (newPassword !== confirmPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    return console.log("저장 버튼 잘 되네여~");
    const formData = new FormData();
    formData.append("username", newUsername);
    formData.append("nickname", newNickname);
    formData.append("password", newPassword);
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }

    // try {
    //     const response = await authInterceptor.post(
    //       "/auth/updateProfile",
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //       }
    //     );
    //   if (response.status === 200) {
    //     alert("프로필이 성공적으로 업데이트되었습니다.");
    //     onClose();
    //   }
    // } catch (error) {
    //   console.error("프로필 업데이트 실패:", error);
    //   alert("프로필 업데이트에 실패했습니다.");
    // }
  };

  return (
    <div className="ModalSignConfig">
      <div className="modal-config__content">
        <div className="modal-config__top">
          <h2 className="modal-config__title">개인 정보 수정</h2>
          <button className="modal-config__close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-config__middle">
          <label className="modal-config__label">
            아이디 변경
            <input
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              placeholder="새로운 아이디"
              className="modal-config__input"
            />
          </label>
          <label className="modal-config__label">
            닉네임 변경
            <input
              type="text"
              value={newNickname}
              onChange={(e) => setNewNickname(e.target.value)}
              placeholder="새로운 닉네임"
              className="modal-config__input"
            />
          </label>
          <label className="modal-config__label">
            비밀번호 변경
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="새로운 비밀번호"
              className="modal-config__input"
            />
          </label>
          <label className="modal-config__label">
            비밀번호 확인
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="비밀번호 확인"
              className="modal-config__input"
            />
          </label>
          <label className="modal-config__label">
            프로필 사진 변경
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="modal-config__input-file"
            />
          </label>
        </div>
        <div className="modal-config__bottom">
          <button
            className="modal-config__save-button"
            onClick={handleSaveChanges}
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
};
