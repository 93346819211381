/*
 * 작성자:
 * 작성일: 240830
 * 설명:
 * 부모 연결:
 * 자식 연결:
 */

import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik"; //로그인 폼 상태 관리
import * as Yup from "yup"; //로그인 유효성 검사
import { useAuth } from "../../asset/util/authContext";
import authInterceptor from "../../asset/util/authIntercepter";

export const ModalSignCheck = ({ isOpen, onClose, onCheck }) => {
  const { isSign, userId, signOut } = useAuth();

  if (!isOpen) return null;

  const validCheck = Yup.object({
    user_pw: Yup.string()
      .matches(/[A-Za-z]/, "비밀번호는 영어를 포함해야 합니다.")
      .matches(/\d/, "비밀번호는 숫자를 포함해야 합니다.")
      .matches(
        /^[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/,
        "비밀번호는 영어, 숫자, 특수문자만 포함할 수 있습니다."
      )
      .required("비밀번호를 입력해주세요."),
  });

  const handleCheck = (values) => {
    const userData = {
      user_pw: values.user_pw,
    };
    authInterceptor
      .post("/auth/user", userData)
      .then((res) => {
        onCheck(res.data.matchResult, userId);
        onClose(); // 팝업 닫기
      })
      .catch((err) => {
        // 에러 처리
        let errorMessage;

        if (err.message === "Failed to refresh token") {
          errorMessage = "로그인 만료! 재로그인 후 다시 시도해주세요";
          alert(errorMessage);
          signOut();
          onClose();
        } else {
          errorMessage = err.response?.data.message || "알 수 없는 오류 발생";
          alert(errorMessage);
        }
      });
  };

  return (
    <div className="ModalSign">
      <div className="modal-sign__content">
        <div className="modal-sign__top">
          <h2 className="modal-sign__title">비밀번호 재확인</h2>
          <button className="modal-sign__close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-sign__middle">
          <Formik
            initialValues={{ user_pw: "" }}
            validationSchema={validCheck}
            onSubmit={handleCheck}
          >
            <Form className="modal-sign__form">
              <Field
                className="modal-sign__input"
                type="password"
                placeholder="비밀번호"
                name="user_pw"
              />
              <ErrorMessage
                className="modal-sign__error-msg"
                name="user_pw"
                component="div"
              />
              <button className="modal-sign__btn" type="submit">
                비밀번호 확인
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
