/*
 * 작성자: 박준우
 * 작성일: 240804 (240817 수정)
 * 설명: 
  - 240817 수정 내용
    로그인, 로그아웃 modal 방식으로 변경
    className BEM 적용
    APP 파일과 분리

 * 부모 연결: App.js
*/
import "../../asset/css/page/SideBarKorea.css"; // Header :: Domestic css

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../asset/util/authContext";

function SideBarKorea({
  toggleSidebar,
  handleSignin,
  handleSignup,
  handleMypage,
}) {
  const { isSign, userId, userLevel, signOut } = useAuth();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [activeMenu, setActiveMenu] = useState(null);

  const handleSidebar = () => {
    toggleSidebar(!isSidebarVisible);
    setIsSidebarVisible(!isSidebarVisible);
  };
  const handleSubMenuToggle = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu(null); // 이미 열려 있으면 닫기
    } else {
      setActiveMenu(menu); // 아니면 열기
    }
  };
  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <div className="SideBarKorea">
      <button
        className={`app__button-toggle ${isSidebarVisible ? "open" : ""}`}
        onClick={handleSidebar}
      >
        ☰
      </button>
      <aside className={`app__sidebar ${isSidebarVisible ? "" : "hidden"}`}>
        <div className="app__logo" onMouseDown={handleMouseDown}>
          <Link to="/">
            <S.Image src="/logo-fulltext-kor.svg" alt="파아란 코스" />
          </Link>
        </div>
        <nav className="app__nav">
          {/* <div
            className={`app__nav-item ${activeMenu === "help" ? "active" : ""}`}
            onClick={() => handleSubMenuToggle("help")}
            onMouseDown={handleMouseDown}
          >
            <h4>도움말</h4>
            {activeMenu === "help" && (
              <div className="app__submenu">
                <Link to="/Help/manual">매뉴얼</Link>
              </div>
            )}
          </div> */}
          <div
            className={`app__nav-item ${
              activeMenu === "inquire" ? "active" : ""
            }`}
            onClick={() => handleSubMenuToggle("inquire")}
            onMouseDown={handleMouseDown}
          >
            <h4>게시판</h4>
            {activeMenu === "inquire" && (
              <div className="app__submenu">
                <Link to="/Inquire">개선 요구 사항</Link>
                <Link to="/Inquire/reqCourse">경로 요청</Link>
              </div>
            )}
          </div>
          {/* <div
            className={`app__nav-item ${
              activeMenu === "student" ? "active" : ""
            }`}
            onClick={() => handleSubMenuToggle("student")}
            onMouseDown={handleMouseDown}
          >
            <h4>대학생과 함께하는</h4>
            {activeMenu === "student" && (
              <div className="app__submenu">
                <Link to="/Student/photo">사진</Link>
              </div>
            )}
          </div> */}
          <div
            className={`app__nav-item ${
              activeMenu === "developer" ? "active" : ""
            }`}
            onClick={() => handleSubMenuToggle("developer")}
            onMouseDown={handleMouseDown}
          >
            <h4>개발진</h4>
            {activeMenu === "developer" && (
              <div className="app__submenu">
                <Link to="/Developer">파아란 이야기</Link>
              </div>
            )}
          </div>
          {isSign && userLevel < 5 && (
            <div
              className={`app__nav-item ${
                activeMenu === "manager" ? "active" : ""
              }`}
              onClick={() => handleSubMenuToggle("manager")}
              onMouseDown={handleMouseDown}
            >
              <h4>관리자</h4>
              {activeMenu === "manager" && (
                <div className="app__submenu">
                  <Link to="/Manager/account">계정 관리</Link>
                  <Link to="/Manager/post">게시글 관리</Link>
                  <Link to="/Manager/file">임시 파일 관리</Link>
                  <Link to="/Manager/error">오류 로그</Link>
                </div>
              )}
            </div>
          )}
        </nav>
        {isSign ? (
          <>
            <div className="app__profile">
              <img src="/logo-text-kor.svg" alt="Profile" />
            </div>
            <div className="app__user-info">
              <h4
                className="app__user-id"
                onClick={handleMypage}
                onMouseDown={handleMouseDown}
              >
                {userId}
              </h4>
              <button
                className="app__button app__button--out"
                onClick={signOut}
                onMouseDown={handleMouseDown}
              >
                로그아웃
              </button>
            </div>
          </>
        ) : (
          <div className="app__buttons">
            <button
              className="app__button app__button--in"
              onClick={handleSignin}
              onMouseDown={handleMouseDown}
            >
              로그인
            </button>
            <button
              className="app__button app__button--up"
              onClick={handleSignup}
              onMouseDown={handleMouseDown}
            >
              회원가입
            </button>
          </div>
        )}
      </aside>
    </div>
  );
}

const S = {
  Image: styled.img`
    width: 200px; /* 이미지 크기 설정 */
    height: auto;
    cursor: pointer;
  `,
};

export default SideBarKorea;
