/*
 * 작성자: 박준우
 * 작성일: 240824
 * 설명: 뷰어용 지도 구현
 * 부모 연결: 
  - DivPost2.js
 */
import "../../asset/css/tool/olVworldMap.css"; // Util :: Map css

import React, { useState } from "react";
import useVworld from "../../asset/util/useVworld";
import {
  Zoom,
  Rotate,
  Attribution,
  ZoomSlider,
  ScaleLine,
  OverviewMap,
  FullScreen,
  MousePosition,
} from "ol/control";
import OlElevationChart from "./olElevationChart";

// MiniMap (단순 View만 제공)
export const DivOlminiMap = ({ file = null }) => {
  const basicControls = {
    zoom: false, // 줌 컨트롤 제거
    rotate: false, // 회전 컨트롤 제거
  };
  const basicInteractions = {
    mouseWheelZoom: false, // 마우스 휠 줌 비활성화
    doubleClickZoom: false, // 더블 클릭 줌 비활성화
    dragPan: false, // 드래그 패닝은 유지 (원한다면 비활성화 가능)
    pinchZoom: false, // 터치 줌 비활성화
  };

  const { mapRef, totalDistance, totalAscent } = useVworld({
    file,
    basicControls,
    basicInteractions,
  });

  return (
    <div className="DivOlminiMap">
      <div
        id="ol_vworld"
        className="minimap__map"
        ref={mapRef}
        style={{ pointerEvents: "none" }} // 모든 포인터 이벤트 비활성화
      >
        <div className="minimap__info">
          <div className="minimap__ascent">
            획득고도
            <br />
            {totalAscent.toFixed(2)} m
          </div>
          <div className="minimap__distance">
            총거리
            <br />
            {(totalDistance / 1000).toFixed(2)} km
          </div>
        </div>
      </div>
    </div>
  );
};

// Map
export const DivOlMap = ({ file = null }) => {
  console.log("olVworldMap ::: DivOlMap", file);
  const [currentMapIndex, setCurrentMapIndex] = useState(0);
  const [currentChartIndex, setCurrentChartIndex] = useState(0);
  const handleMapDrag = (index) => {
    setCurrentMapIndex(index);
  };
  const handleChartDrag = (index) => {
    setCurrentMapIndex(index); // 차트 빨간점 이동 위한 미봉책
    setCurrentChartIndex(index);
  };

  const basicControls = {
    rotate: false, // 회전 컨트롤 제거
  };
  const customControls = [new FullScreen()];
  const { mapRef, elevationData, totalDistance, totalAscent } = useVworld({
    file,
    basicControls,
    customControls,
    onDrag: handleMapDrag,
    currentIndex: currentChartIndex,
  });

  return (
    <div className="DivOlMap">
      <div id="ol_vworld" className="map__map" ref={mapRef}>
        <div className="map__info">
          <div className="map__ascent">
            획득고도: {totalAscent.toFixed(2)} m
          </div>
          <div className="map__distance">
            총거리: {(totalDistance / 1000).toFixed(2)} km
          </div>
        </div>
      </div>
      {file && (
        <div className="map__chart-elevation">
          <OlElevationChart
            elevationData={elevationData}
            currentIndex={currentMapIndex}
            onDrag={handleChartDrag}
          />
        </div>
      )}
    </div>
  );
};
