/*
 * 작성자: 박준우
 * 작성일: 241029
 * 설명: 게시글 추가 팝업
 */
import "../../asset/css/component/ModalPost_Add.css"; // Modal 컴포넌트용 CSS

import React, { useState, useEffect } from "react";
import axios from "axios";
import manageBoard from "../../asset/util/manageBoard.js";
import { useAuth } from "../../asset/util/authContext.js";

export const ModalPost_Add = ({ isOpen, onClose, category }) => {
  const { userId } = useAuth();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isOpen, isUploading]);

  // 창 나가기 전에 초기화
  const handleBeforeUnload = (event) => {
    if (isUploading) return;

    if (
      document.getElementById("postTitle").value !== "" ||
      document.getElementById("postContent").value !== ""
    ) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  const handleClose = () => {
    if (
      document.getElementById("postTitle").value !== "" ||
      document.getElementById("postContent").value !== ""
    ) {
      const chkClose = window.confirm(
        "정말로 창을 닫으시겠습니까? 작성 중인 내용이 사라질 수 있습니다."
      );

      if (chkClose) {
        onClose(); // 창을 닫는 함수 호출
      }
    } else {
      onClose();
    }
  };

  const handleUpload = async () => {
    setIsUploading(true);

    const title = document.getElementById("postTitle").value;
    const content = document.getElementById("postContent").value;

    if (title === "" || content === "") {
      alert("제목과 내용을 모두 입력해 주세요.");
      setIsUploading(false);
      return;
    }

    const data = {
      category: category,
      category_detail: "all",
      title: title,
      content: content,
      user_id: userId,
    };

    try {
      await manageBoard({
        type: "insert",
        data: data,
      });

      alert("저장이 완료되었습니다.");
      // 저장 후 페이지를 새로고침하지 않고, 입력한 데이터를 초기화
      document.getElementById("postTitle").value = "";
      document.getElementById("postContent").value = "";

      // one page action 이기 때문에 강제 재렌더링 시도해야 함?
      window.location.reload();
    } catch (error) {
      console.error("저장 중 오류 발생:", error);
      alert("저장 중 오류가 발생했습니다. 다시 시도해 주세요.");
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="ModalPost_Add">
      <div className="modal-post__content">
        <div className="modal-post__header">
          <button className="modal-post__close-button" onClick={handleClose}>
            &times;
          </button>
        </div>
        <div className="modal-post__body">
          <input
            type="text"
            id="postTitle" // ID를 부여하여 저장 시 DOM에서 값 읽기
            className="modal-post__title-input"
          />
          <textarea
            id="postContent" // ID를 부여하여 저장 시 DOM에서 값 읽기
            className="modal-post__content-input"
          />
        </div>
        <div className="modal-post__footer">
          <div className="modal-post__actions">
            <button className="modal-post__button-save" onClick={handleUpload}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
