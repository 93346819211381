/*
 * 작성자:
 * 작성일:
 * 설명: tooltip 적용이 잘 안되네...
 * 부모 연결:
 * 자식 연결:
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { DivOlminiMap } from "../tool/olVworldMap"; // 맵 컴포넌트
import manageFile from "../../asset/util/manageFile"; // 파일 관리 유틸
import "../../asset/css/component/DivPostMap.css"; // Utility :: DivPostMap css

export const DivPostMap = ({ props, onClick }) => {
  const [mapData, setMapData] = useState({}); // 개별 게시물에 대한 mapData를 저장할 객체

  useEffect(() => {
    props.forEach((post) => {
      // 이미 mapData에 저장된 postId가 아닌 경우에만 getFiles 호출
      if (post.post_file && !mapData[post.post_id]) {
        getFiles(post.post_file, post.post_id);
      }
    });
  }, [props]);

  const getFiles = async (postFile, postId) => {
    try {
      const condition = { post_file: postFile };
      const result = await manageFile({
        type: "getDB",
        data: condition,
      });

      for (const fileItem of result.rows) {
        await getMaps(fileItem, postId);
      }
    } catch (error) {
      console.error("파일 가져오기 실패:", error);
    }
  };

  const getMaps = async (fileItem, postId) => {
    try {
      const response = await axios.get("/file/download", {
        params: {
          fileName: fileItem.file_path,
          type: "geojson",
        },
        responseType: "blob",
      });

      const file = new File([response.data], fileItem.file_name);
      // 상태 업데이트 후 로그 출력
      setMapData((prevMapData) => {
        const updatedMapData = {
          ...prevMapData,
          [postId]: file,
        };
        console.log("DivPostMap ::: getMaps:", updatedMapData); // 여기서 최신 값을 출력
        return updatedMapData;
      });
    } catch (error) {
      console.error("파일 다운로드 오류:", error);
    }
  };

  return (
    <div className="DivPost2">
      <ul className="div-post2__grid">
        {props.map((post) => (
          <li
            key={post.post_id}
            className="div-post2__wrap"
            onClick={() => onClick(post)}
          >
            <div className="div-post2__header">
              {mapData[post.post_id] ? (
                <DivOlminiMap file={mapData[post.post_id]} />
              ) : (
                <p>Loading map...</p>
              )}
            </div>
            <div className="div-post2__info">
              <div className="div-post2__title" data-tooltip={post.title}>
                {post.title}
              </div>
              <div className="div-post2__footer">
                <span className="div-post2__user">
                  ID: {post.user_id || "익명"}
                </span>
                <span className="div-post2__date">
                  등록일: {format(new Date(post.created_at), "yyyy-MM-dd")}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
