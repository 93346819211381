/*
 * 작성자: 박준우  
 * 작성일: 240811
 * 설명: 토큰 검증
    authToken 모듈은 토큰을 검증하고 재발급하는 특정 로직을 담당하기 때문에, 인증 관련 API 요청을 직접 수행하는 모듈입니다. 
    이 모듈에서는 주로 내부적으로 axios 요청을 만들어 토큰을 검증하거나 갱신합니다. 
    이 과정에서 특정 API 요청 전에 다른 토큰 검증 로직이 추가될 필요가 없기 때문에 authInterceptor를 사용하지 않습니다.
 * 부모 연결: 
 * 자식 연결: authInterceptor
*/

import axios from "axios";

const authToken = axios.create({ timeout: 1000 });

// accessToken 검증
authToken.accessVerify = async() => {
    try {
        const response = await axios.post('/auth/accVer', {}, {
            headers: { 
                'Content-Type': 'application/json',
            },
            withCredentials: true, // httpOnly 쿠기 전송
        });
        console.log("authToken ::: accVer ", response);
        return response; // 서버에서 토큰이 유효한지 확인한 결과를 반환
    } catch (error) {
        console.error("토큰 검증 중 오류 발생:", error);
        return false;
    }
};

// accessToken 재발급
authToken.getNewAccessToken = async () => {
    try {
        const response = await axios.post('/auth/refresh', {}, {
            headers: { 
                'Content-Type': 'application/json',
            },
            withCredentials: true, // httpOnly 쿠기 전송
        });
        console.log("authToken ::: getNewAcc ", response);
        return response;
    } catch (error) {
        console.error("토큰 갱신 오류:", error);
        return null;
    }
};

export default authToken;