import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useModal } from "../../asset/util/useModal";
import { hashingKey } from "../../asset/util/generateKey";
import { compareKey } from "../../asset/util/generateKey";
import { useAuth } from "../../asset/util/authContext";
import { ModalSignCheck } from "./ModalSignCheck";
import { ModalSignConfig } from "./ModalSignConfig";

function MyPage() {
  const navigate = useNavigate(); // 페이지 이동 용도
  const location = useLocation(); // 현재 url에서 쿼리 파라미터 읽는 용도
  const signcheckModal = useModal(); // mypageModal을 useModal로 선언
  const signconfigModal = useModal(); // signconfigModal을 useModal로 선언
  const { isSign, userId } = useAuth();
  const [activeTab, setActiveTab] = useState("registered"); // 초기 탭 설정

  const handleSignCheck = async (isResult, id) => {
    if (isResult) {
      console.log("확인 완료 페이지 이동합니데이~");
      signcheckModal.closeModal();
      const key = await hashingKey(id); // id 받는 거 기다렸다가 넘겨야함
      navigate(`/Mypage?key=${key}`);
      signconfigModal.openModal();
    } else {
      alert("비밀번호 오류");
    }
  };

  const handleSignConfig = () => {
    // 개인 정보 수정 onClick
    signcheckModal.openModal(); //
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "registered":
        return (
          <div className="courses">
            <div className="course-item">
              <img src="placeholder.jpg" alt="Course Image" />
              <p>가평 자라섬 그란폰도</p>
              <p>등록일: 2024.01.01</p>
            </div>
            <div className="course-item">
              <img src="placeholder.jpg" alt="Course Image" />
              <p>가평 자라섬 그란폰도</p>
              <p>등록일: 2024.01.01</p>
            </div>
            <div className="course-item">
              <img src="placeholder.jpg" alt="Course Image" />
              <p>가평 자라섬 그란폰도</p>
              <p>등록일: 2024.01.01</p>
            </div>
          </div>
        );
      case "liked":
        return (
          <div className="courses">
            <div className="course-item">
              <img src="placeholder.jpg" alt="Course Image" />
              <p>강릉 해변 코스</p>
              <p>좋아요 날짜: 2024.02.15</p>
            </div>
          </div>
        );
      case "reviewed":
        return (
          <div className="courses">
            <div className="course-item">
              <img src="placeholder.jpg" alt="Course Image" />
              <p>서울 시내 도보 코스</p>
              <p>리뷰 작성일: 2024.03.10</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // 241115 박준우 - 레거시
  // 단순 MyPage 접근은 사용자 인증 받기 같은거 안합니다.
  // useEffect(() => {
  //   const checkAuth = async () => {
  //     const params = new URLSearchParams(location.search);
  //     const key = params.get("key"); // 비밀번호 입력 후 해싱한 key 받기

  //     if (!key) {
  //       navigate("/");
  //       alert("잘못된 접근, redirected to Main.");
  //       return;
  //     }

  //     const isKeyMatched = await compareKey(userId, key);
  //     if (!isKeyMatched) {
  //       navigate("/");
  //       alert("잘못된 접근, redirected to Main.");
  //       return;
  //     }
  //   };
  //   checkAuth();
  // }, []);

  return (
    <div className="container">
      {/* 상단: 계정 설명 */}
      <div className="account-section">
        <h2>마이페이지</h2>
        <div className="info">
          <div className="info-item">
            <strong>계정관리</strong>
            <button className="edit-button" onClick={handleSignConfig}>
              개인 정보 수정
            </button>
            <p>ID: {userId}</p>
            <p>PW: *********</p>
          </div>
          <div className="info-item">
            <strong>이름</strong>
            <p>홍길동</p>
          </div>
        </div>
      </div>

      {/* 중단: 나의 활동 */}
      <div className="activity-section">
        <div className="activity-header">
          <h3>나의 활동</h3>
          <p>레벨: 하얀 코스</p>
        </div>
        <div className="stats">
          <div className="stats-item">
            등록한 코스: 0개 | 좋아한 코스: 3개 | 리뷰한 코스: 3개
          </div>
          <div className="stats-item">
            다음 레벨까지: 등록한 코스: 1개 | 좋아한 코스: 3개 | 리뷰한 코스:
            3개 남았습니다
          </div>
        </div>
      </div>

      {/* 하단: 코스 정보 탭 */}
      <div className="course-section">
        <h3>코스 정보</h3>
        <div className="tabs">
          <button
            className={`tab ${activeTab === "registered" ? "active" : ""}`}
            onClick={() => setActiveTab("registered")}
          >
            등록한 코스
          </button>
          <button
            className={`tab ${activeTab === "liked" ? "active" : ""}`}
            onClick={() => setActiveTab("liked")}
          >
            좋아한 코스
          </button>
          <button
            className={`tab ${activeTab === "reviewed" ? "active" : ""}`}
            onClick={() => setActiveTab("reviewed")}
          >
            리뷰한 코스
          </button>
        </div>
        <div className="tab-content">{renderTabContent()}</div>
      </div>

      <ModalSignCheck
        isOpen={signcheckModal.isModalOpen} // 모달 열림 상태 전달
        onClose={signcheckModal.closeModal} // 모달 닫기 함수
        onCheck={handleSignCheck} // 비밀번호 재검 여부
      />
      <ModalSignConfig
        isOpen={signconfigModal.isModalOpen} // 모달 열림 상태 전달
        onClose={signconfigModal.closeModal} // 모달 닫기 함수
      />
    </div>
  );
}

export default MyPage;
