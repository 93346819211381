/*----- npm import -----*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from'react-router-dom';

/*----- css import -----*/
import 'normalize.css'
import './asset/css/index.css'; // 공통 css

/*----- component import -----*/
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
