/*
 * 작성자:
 * 작성일: 240901 수정
 * 설명: 유저 권한 부여
 * 부모 연결:
 * 자식 연결:
 */

import "../../asset/css/page/Inquire.css"; // Main:: 문의 css

import React, { useState, useEffect } from "react";
import { useAuth } from "../../asset/util/authContext";
import MarkDown from "@uiw/react-md-editor";
import MDEditor from "@uiw/react-md-editor";
import Pagination from "react-js-pagination";
import manageBoard from "../../asset/util/manageBoard";
import { DivPost1 } from "../component/DivPost1";
import { useModal } from "../../asset/util/useModal";
import { ModalPost_Add } from "../component/ModalPost_Add"; // Modal 컴포넌트
import { ModalPost_View } from "../component/ModalPost_View"; // Modal 컴포넌트

function Inquire() {
  const [isNotice, setNotice] = useState({ exists: false, post_id: "" }); // 기존 공지사항(마크다운) 유무
  const [isMDValue, setMDValue] = useState("");
  const [isPost, setPost] = useState([]); // 읽어들은 데이터의 row 정보
  const [selectedPost, setSelectedPost] = useState([]); // 선택된 post
  const [postPerPage] = useState(5); // 페이지당 보여줄 게시글 수
  const [isPage, setPage] = useState(1); // 현재 페이지
  const [isPostCnt, setPostCnt] = useState(0); // 읽어들인 데이터 개수
  const { isSign, userId, userLevel } = useAuth();

  // 모달 상태 관리
  const {
    isModalOpen: isAddModalOpen,
    openModal: openAddModal,
    closeModal: closeAddModal,
  } = useModal(); // 게시글 추가
  const {
    isModalOpen: isViewModalOpen,
    openModal: openViewModal,
    closeModal: closeViewModal,
  } = useModal(); // 게시글 조회

  const getNotice = async () => {
    try {
      const condition = { category: 15 };
      const result = await manageBoard({
        type: "select",
        data: condition,
      });
      if (result.rowCount === 1) {
        setNotice({
          exists: true,
          postId: result.rows[0].post_id,
        });
        setMDValue(result.rows[0].content);
      } else {
        console.log(
          "Inquire ::: getNotcie : !!오류 발생!! 관리자에게 문의하세요"
        );
        setNotice({
          exists: false,
          postId: "",
        });
      }
    } catch (error) {
      console.error(
        "Inquire ::: getNotcie : 공지사항을 불러오는 중 오류 발생:",
        error
      );
      alert("공지사항을 불러오는 중 오류가 발생했습니다. 다시 시도해주세요.");
    }
  };

  const getPost = async () => {
    const condition = { category: 11 };
    const result = await manageBoard({
      type: "select",
      data: condition,
    });
    setPostCnt(result.rowCount);
    setPost(result.rows.reverse()); // 데이터 최신순 정렬
  };

  const getPostPerPage = () => {
    const lastPost = isPage * postPerPage;
    const firstPost = lastPost - postPerPage;
    return isPost.slice(firstPost, lastPost);
  };

  const handlePaging = (pageNum) => {
    setPage(pageNum);
  };

  useEffect(() => {
    getNotice();
    getPost();
  }, []);

  const handleNoticeInsert = async (event) => {
    event.preventDefault();
    if (userLevel > 5) {
      alert("잘못된 접근");
      return;
    }

    const markdownValue = isMDValue;

    const data = {
      category: 15,
      user_id: userId,
      title: "MarkDown-Notice",
      content: markdownValue,
    };

    let result = "";

    if (isNotice.exists) {
      data.post_id = isNotice.post_id;
      result = await manageBoard({
        type: "update",
        data: data,
      });
    }

    if (result) {
      alert("공지사항 등록 완료");
    } else {
      alert("공지사항 처리 중 오류 발생");
    }
  };

  // 게시물 클릭 시 모달 열기
  const handlePostClick = async (post) => {
    console.log("Inquire : ", post);
    setSelectedPost(post);
    openViewModal(true);
  };

  return (
    <div className="Inquire">
      <div className="inquire__top">
        {isSign && userLevel < 5 ? (
          <div className="inquire__notice">
            <h1 className="inquire__notice-title"> 개 선 사 항 (관리자) </h1>
          </div>
        ) : (
          <div className="inquire__notice">
            <h1 className="inquire__notice-title"> 개 선 사 항 </h1>
          </div>
        )}
      </div>
      <div className="inquire__bottom">
        <div className="inquire__markdown">
          {isSign && userLevel < 5 ? (
            <div className="inquire__markdown-editor">
              <MDEditor
                id="markdownEditor"
                value={isMDValue}
                onChange={setMDValue}
                style={{ height: "100%", width: "100%" }}
              />
              <button
                className="inquire__markdown-submit"
                onClick={handleNoticeInsert}
              >
                등록
              </button>
            </div>
          ) : (
            <div className="inquire__markdown-viewer">
              <MarkDown.Markdown
                source={isMDValue}
                style={{ height: "100%" }}
              />
            </div>
          )}
        </div>
        <div className="inquire__board">
          <div id="board_write" className="inquire__board-write">
            <button
              id="board_submit"
              className="inquire__board-submit"
              onClick={openAddModal}
            >
              등록
            </button>
          </div>
          <div id="board_list" className="inquire__board-list">
            <DivPost1
              props={getPostPerPage()}
              category={11}
              onClick={handlePostClick}
            />
            <div id="list_page" className="inquire__pagination">
              <Pagination
                totalItemsCount={isPostCnt}
                onChange={handlePaging}
                activePage={isPage}
                itemsCountPerPage={postPerPage}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalPost_Add
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        category={11}
      />
      <ModalPost_View
        isOpen={isViewModalOpen}
        onClose={closeViewModal}
        post={selectedPost}
      />
    </div>
  );
}

export default Inquire;
