/*
 * 작성자: 박준우
 * 작성일: 241029
 * 설명: 지도 게시글 조회 및 수정 팝업
 *  onClose={onClose}: 버튼을 눌렀을 때 onClose가 실행
    onClose={onClose()}: 페이지가 로드될 때 onClose가 실행버튼 클릭과는 관계가 없어짐
 * 부모 연결: Home.js
 */

import "../../asset/css/component/ModalPost_View.css"; // Modal 컴포넌트용 CSS

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  AiOutlineLike,
  AiFillLike,
  AiOutlineDislike,
  AiFillDislike,
} from "react-icons/ai";
import { format } from "date-fns";
import { useAuth } from "../../asset/util/authContext.js";
import manageBoard from "../../asset/util/manageBoard.js";
import DivComment1 from "../component/DivComment1.js";

export const ModalPost_View = ({ isOpen, onClose, post }) => {
  const navigate = useNavigate();
  const { isSign, userId, userLevel } = useAuth();

  const handlePostUpdate = (post_id, user_id, newTitle, newContent) => {
    if (isSign && (post.user_id === userId || userLevel < 5)) {
      try {
        const data = {
          post_id: post_id,
          title: newTitle,
          content: newContent,
          user_id: user_id,
        };
        manageBoard({
          type: "update",
          data: data,
        });
        window.location.reload();
      } catch (err) {
        console.error("ModalPost_View ::: 수정실패 : ", err);
      }
    } else {
      alert("본인이 쓴 글만 삭제가 가능합니다.");
    }
  };

  const handlePostDelete = (post_id, user_id) => {
    if (isSign && (user_id === userId || userLevel < 5)) {
      try {
        const data = {
          user_id: user_id,
          post_id: post_id,
        };
        manageBoard({
          type: "delete",
          data: data,
        });
        window.location.reload();
      } catch (err) {
        console.error("ModalPost_View ::: 삭제실패 : ", err);
      }
    } else {
      alert("본인이 쓴 글만 삭제가 가능합니다.");
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="ModalPost2">
      <EditablePostItem
        post={post}
        onClose={onClose}
        onUpdate={handlePostUpdate}
        onDelete={handlePostDelete}
      />
    </div>
  );
};

const EditablePostItem = ({ post, onClose, onUpdate, onDelete }) => {
  const { isSign, userId, userLevel } = useAuth();
  const [isEdit, setEdit] = useState(false);
  const [likeStatus, setLikeStatus] = useState(false);
  const [dislikeStatus, setDislikeStatus] = useState(false);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 post의 초기 상태 반영
    if (post) {
      setLikeStatus(post.user_liked || false);
      setDislikeStatus(post.user_disliked || false);
    }
  }, [post]);

  const handleEdit = () => {
    if (isSign && (post.user_id === userId || userLevel < 5)) {
      setEdit(true);
    } else {
      alert("본인이 쓴 글만 수정이 가능합니다.");
    }
  };

  const handleUpdate = () => {
    const title = document.getElementById("postTitle").value;
    const content = document.getElementById("postContent").value;
    onUpdate(post.post_id, post.user_id, title, content);
    setEdit(false); // 수정 모드 종료
  };

  const handleLikeDislike = async (type) => {
    if (!isSign) {
      return alert("로그인 후 이용할 수 있습니다.");
    }

    const status = type === "like" ? likeStatus : dislikeStatus;
    const data = {
      db: type,
      post_id: post.post_id,
      user_id: userId,
    };

    try {
      let response;

      if (type === "like") {
        if (status) {
          // 좋아요 취소
          response = await axios.delete("/evaluate", { params: data });
          setLikeStatus(false);
        } else {
          // 싫어요 상태를 취소
          if (dislikeStatus) {
            await axios.delete("/evaluate", {
              params: { db: "dislike", post_id: post.post_id, user_id: userId },
            });
            setDislikeStatus(false);
          }
          // 좋아요 추가
          response = await axios.post("/evaluate", data);
          setLikeStatus(true);
        }
      } else if (type === "dislike") {
        if (status) {
          // 싫어요 취소
          response = await axios.delete("/evaluate", { params: data });
          setDislikeStatus(false);
        } else {
          // 좋아요 상태를 취소
          if (likeStatus) {
            await axios.delete("/evaluate", {
              params: { db: "like", post_id: post.post_id, user_id: userId },
            });
            setLikeStatus(false);
          }
          // 싫어요 추가
          response = await axios.post("/evaluate", data);
          setDislikeStatus(true);
        }
      }

      return response.data;
    } catch (error) {
      console.error("evaluate Error:", error);
      throw error;
    }
  };

  return (
    <div className="ModalPostMap_View">
      <div className="modal__content">
        <div className="modal__left">
          {isEdit ? (
            <div className="modal__board">
              <input
                type="text"
                id="postTitle"
                defaultValue={post.title}
                className="post__title-input"
              />
              <textarea
                id="postContent"
                defaultValue={post.content}
                className="post__content-input"
              />
            </div>
          ) : (
            <div className="modal__board">
              <div className="post__title">{post.title}</div>
              <div className="post__content">{post.content}</div>
            </div>
          )}
        </div>
        <div className="modal__right">
          <button className="modal__close" onClick={onClose}>
            &times;
          </button>
          <div className="modal__footer">
            <span className="post__author">
              작성자: {post.user_id || "익명"}
            </span>
            <span className="post__date">
              등록일: {format(new Date(post.created_at), "yyyy-MM-dd")}
            </span>
            <span className="post__date">
              수정일: {format(new Date(post.updated_at), "yyyy-MM-dd")}
            </span>
            {isSign && (post.user_id === userId || userLevel < 5) && (
              <div className="post__actions">
                {isEdit ? (
                  <button
                    id="btnSave"
                    className="btn btn--save"
                    onClick={handleUpdate}
                  >
                    저장
                  </button>
                ) : (
                  <>
                    <button
                      id="btnUpdate"
                      className="btn btn--update"
                      onClick={handleEdit}
                    >
                      수정
                    </button>
                    <button
                      id="btnDelete"
                      className="btn btn--delete"
                      onClick={() => onDelete(post.post_id, post.user_id)}
                    >
                      삭제
                    </button>
                  </>
                )}
              </div>
            )}
            <button
              className="post__button post__button--like"
              onClick={() => handleLikeDislike("like")}
            >
              {likeStatus ? <AiFillLike /> : <AiOutlineLike />}
            </button>
            <button
              className="post__button post__button--dislike"
              onClick={() => handleLikeDislike("dislike")}
            >
              {dislikeStatus ? <AiFillDislike /> : <AiOutlineDislike />}
            </button>
          </div>
          <DivComment1 postId={post.post_id} />
        </div>
      </div>
    </div>
  );
};
