/*
 * 작성자: 박준우
 * 작성일: 240911
 * 설명: home 화면
 * 부모 연결:
 * 자식 연결:
 */
import "../../asset/css/page/Home.css"; // Main:: Home css
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // 추가된 부분
import axios from "axios";
import Pagination from "react-js-pagination";
import manageBoard from "../../asset/util/manageBoard";
import manageFile from "../../asset/util/manageFile";
import { useModal } from "../../asset/util/useModal";
import { DivPostMap } from "../component/DivPostMap"; // 리스트 컴포넌트
import { ModalPostMap_Add } from "../component/ModalPostMap_Add"; // Modal 컴포넌트
import { ModalPostMap_View } from "../component/ModalPostMap_View"; // Modal 컴포넌트

function Home() {
  const navigate = useNavigate(); // URL 변경을 위해 useNavigate 사용
  const location = useLocation(); // 현재 URL 정보를 가져오기 위해 useLocation 사용

  const [isPosts, setPosts] = useState([]); // 읽어들은 데이터의 row 정보
  const [postPerPage] = useState(10); // 페이지당 보여줄 게시글 수
  const [isPage, setPage] = useState(1); // 현재 페이지
  const [isPostCnt, setPostCnt] = useState(0); //읽어들인 데이터 개수

  const {
    isModalOpen: isAddModalOpen,
    openModal: openAddModal,
    closeModal: closeAddModal,
  } = useModal();
  const {
    isModalOpen: isViewModalOpen,
    openModal: openViewModal,
    closeModal: closeViewModal,
  } = useModal();

  const getPosts = async () => {
    try {
      const condition = { category: 1 };
      const result = await manageBoard({
        type: "select",
        data: condition,
      });
      setPostCnt(result.rowCount);
      const sortedPosts = [...result.rows].reverse();
      setPosts(sortedPosts);
    } catch (error) {
      console.error("게시글 가져오기 실패:", error);
    }
  };

  const getPostPerPage = () => {
    // 한페이지에 보일 게시글 처리
    const lastPost = isPage * postPerPage;
    const firstPost = lastPost - postPerPage;
    return isPosts.slice(firstPost, lastPost);
  };

  const handlePaging = (pageNum) => {
    // 현재 페이지 처리
    setPage(pageNum);
  };

  useEffect(() => {
    const loadData = async () => {
      await getPosts(); // 게시물 데이터를 먼저 불러옴
    };

    loadData();
  }, []); // 컴포넌트가 처음 로드될 때 한 번만 실행

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const postId = queryParams.get("post");

    // isPosts가 로드된 상태에서만 처리
    if (isPosts.length > 0 && postId) {
      const post = isPosts.find((p) => p.post_id === postId);

      if (post) {
        handlePostClick(post); // post가 있으면 모달 열기
      } else {
        alert("해당 게시글을 찾을 수 없습니다.");
        navigate("/");
      }
    }
  }, [isPosts]); // isPosts가 업데이트된 이후에만 실행

  // 검색용 콤보박스 처리
  const [isSelected, setSelected] = useState("all");

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleSearch = async () => {
    const searchKeyword = document.getElementById("searchInput").value; // 검색어 DOM에서 직접 읽기
    try {
      const condition = {
        category: 1,
      };
      if (isSelected && isSelected !== "all") {
        condition.category_detail = isSelected;
      }
      if (searchKeyword) {
        condition.title = searchKeyword;
      }

      const result = await manageBoard({
        type: "select",
        data: condition,
      });
      setPostCnt(result.rowCount);
      const sortedPosts = [...result.rows].reverse();
      setPosts(sortedPosts);
    } catch (error) {
      console.error("게시글 가져오기 실패:", error);
    }
  };

  // 게시물 클릭 시 모달 열기
  const [selectedPost, setSelectedPost] = useState([]); // 선택된 게시물
  const [selectedFiles, setSelectedFiles] = useState([]); // 선택된 파일
  const [selectedMap, setSelectedMap] = useState([]); // 선택된 지도

  const getPostFiles = async (postFile) => {
    try {
      const condition = { post_file: postFile };
      const result = await manageFile({
        type: "getDB",
        data: condition,
      });
      setSelectedFiles(result.rows);

      // 맵 파일 데이터 로드
      const mapFiles = await Promise.all(
        result.rows.map((fileItem) => getMapFile(fileItem))
      );
      setSelectedMap(mapFiles);
    } catch (error) {
      console.error("파일 가져오기 실패:", error);
    }
  };

  const getMapFile = async (fileItem) => {
    try {
      const response = await axios.get("/file/download", {
        params: {
          fileName: fileItem.file_path,
          type: "geojson",
        },
        responseType: "blob", // 응답 데이터 타입을 blob으로 설정
      });

      // 서버로부터 받은 blob 데이터를 File 객체로 변환
      const file = new File([response.data], fileItem.file_name);
      return file;
    } catch (error) {
      console.error("파일 다운로드 오류:", error);
      return null;
    }
  };

  const handlePostClick = async (post) => {
    setSelectedPost(post);
    if (post.post_file) {
      // 파일 및 맵 데이터 로드
      await getPostFiles(post.post_file);
    }
    // URL에 post ID 추가
    navigate(`/?post=${post.post_id}`);
    openViewModal(true);
  };

  const closeViewModalWithURL = () => {
    closeViewModal();
    // 모달을 닫으면서 URL을 원래대로 복원
    navigate("/");
  };

  return (
    <div className="Home">
      <div className="home__body">
        <div className="home__search-and-add">
          <div className="home__search-box">
            <div className="home__dropdown">
              <select
                value={isSelected}
                onChange={handleChange}
                className="home__select"
              >
                <option value="all">전체</option>
                <option value="run">러닝</option>
                <option value="ride">라이딩</option>
              </select>
            </div>
            <div className="home__field">
              <input
                type="text"
                id="searchInput" // DOM에서 값 직접 읽기 위해 id 부여
                className="home__input"
                placeholder="검색"
              />
              <button className="home__btn-search" onClick={handleSearch}>
                검색
              </button>
            </div>
            <button className="home__btn-add-post" onClick={openAddModal}>
              게시글 추가
            </button>
          </div>
        </div>

        {isPosts.length > 0 ? (
          <div className="home__board">
            <DivPostMap
              props={getPostPerPage()}
              onClick={handlePostClick} // 게시물 클릭 시 핸들러
            />
            <div className="home__pagination-wrapper">
              <Pagination
                totalItemsCount={isPostCnt} // 전체 item 개수
                onChange={handlePaging} // page 변화시
                activePage={isPage} // 현재 page (default: 1)
                itemsCountPerPage={postPerPage} // page당 보여줄 item 개수 (default: 10)
              />
            </div>
          </div>
        ) : (
          <div className="home__board">
            <p className="home__msg-results">검색된 결과가 없습니다.</p>
          </div>
        )}
      </div>

      <ModalPostMap_Add isOpen={isAddModalOpen} onClose={closeAddModal} />
      <ModalPostMap_View
        isOpen={isViewModalOpen}
        onClose={closeViewModalWithURL} // 모달 닫을 때 URL 변경
        post={selectedPost}
        files={selectedFiles}
        map={selectedMap}
      />
    </div>
  );
}

export default Home;
