/*
 * 작성자: 
 * 작성일: 
 * 설명: hook 이기 때문에 use 고정
 * 
 * 
 * 
 * 예시: 
    import { useModal } from './path-to-your-useModal';

    function ExampleComponent() {
      const { isModalOpen, openModal, closeModal } = useModal();

      return (
        <div>
          <button onClick={openModal}>Open Modal</button>
          {isModalOpen && (
            <div className="modal">
              <p>Modal Content</p>
              <button onClick={closeModal}>Close Modal</button>
            </div>
          )}
        </div>
      );
    }
 * 부모 연결: 
 * 자식 연결: 
*/
import { useState } from "react";

export function useModal() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const reloadModal = () => {
    setModalOpen(false);
    window.location.reload();
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
    reloadModal,
  };
}
