// 241105
// 로딩 text

import React from "react";
import Typewriter from "typewriter-effect";
import styled, { css } from "styled-components";

function AuthContextType() {
  return (
    <S.Text>
      <Typewriter
        options={{
          strings: ["파아란 코스", "로딩 중...", "Paaran course", "Loading..."],
          autoStart: true,
          loop: true,
          delay: 100, // typing 간격
          deleteSpeed: 50, // 삭제 간격
        }}
      />
    </S.Text>
  );
}

const S = {
  Text: styled.div`
    ${css`
      @font-face {
        font-family: "HakgyoansimDunggeunmisoTTF-B";
        src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2408-5@1.0/HakgyoansimDunggeunmisoTTF-B.woff2")
          format("woff2");
        font-weight: 200;
        font-style: normal;
      }
    `};
    font-family: "HakgyoansimDunggeunmisoTTF-B", sans-serif;
    color: #ffffff;
    font-size: 24px;
  `,
};

export default AuthContextType;
