/*
 * 작성자: 
 * 작성일: 
 * 설명:
    - uniqueKey: 
        post와 file 연결을 위한 id 생성
    - 
 * 부모 연결: 
 * 자식 연결: 
*/
const { nanoid } = require('nanoid');
const CryptoJS = require('crypto-js');

export const uniqueKey = () => {
    return nanoid(10); // 10자리의 짧은 ID 생성
}

export const hashingKey = async (userId) => {
    // 현재 연월일시 정보를 사용하여 해시 키 생성
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
  
    const salt = `${year}${month}${day}${hour}`; // YYYYMMDDHH 형식
    const combinedValue = userId + salt;

    // SHA-256 해싱
    return CryptoJS.SHA256(combinedValue).toString(CryptoJS.enc.Hex);
}

export const compareKey = async (userId, hashedKey) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
  
    const salt = `${year}${month}${day}${hour}`; // YYYYMMDDHH 형식
    const combinedValue = userId + salt;

    // 해싱한 값과 비교
    const generatedHash = CryptoJS.SHA256(combinedValue).toString(CryptoJS.enc.Hex);
    return generatedHash === hashedKey;
}