import React, { useRef, useMemo, useCallback } from "react"; // React import
import { Line } from "react-chartjs-2"; // Line 차트 컴포넌트
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js"; // Chart.js 구성 요소
import annotationPlugin from "chartjs-plugin-annotation"; // 애노테이션 플러그인

// Chart.js에 구성 요소와 플러그인 등록
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  annotationPlugin
);

// 커스텀 플러그인 정의: 빨간 점 그리기
const verticalLinePlugin = {
  id: "verticalLinePlugin",
  afterDraw: function (chart) {
    // 활성화된 툴팁이 있는지 확인
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx; // Canvas 컨텍스트
      const activePoint = chart.tooltip._active[0];
      const x = activePoint.element.x;
      const y = activePoint.element.y; // 데이터 포인트의 y 좌표

      // 빨간 점 그리기
      ctx.save(); // 현재 상태 저장
      ctx.beginPath();
      ctx.arc(x, y, 6, 0, 2 * Math.PI); // 반지름 6의 원 그리기
      ctx.fillStyle = "red"; // 색상 설정
      ctx.fill();
      ctx.restore(); // 상태 복원
    }
  },
};

// 경사도 계산 함수
const calculateSlope = (p1, p2) => {
  if (
    !p1 ||
    !p2 ||
    typeof p1.elevation !== "number" ||
    typeof p2.elevation !== "number"
  ) {
    return 0;
  }
  const verticalDistance = p2.elevation - p1.elevation; // 고도 차이
  const horizontalDistance = p2.distance - p1.distance; // 거리 차이
  return (verticalDistance / horizontalDistance) * 100; // 경사도 계산 (%)
};

// 경사도에 따른 색상 결정 함수
const getColorForSlope = (slope) => {
  switch (true) {
    case slope <= -12.5:
      return "rgb(0, 0, 255)"; // 파랑
    case slope <= -7.5:
      return "rgb(0, 85, 255)"; // 파랑-청록
    case slope <= -2.5:
      return "rgb(0, 170, 255)"; // 청록
    case slope <= 2.5:
      return "rgb(0, 255, 0)"; // 초록
    case slope <= 7.5:
      return "rgb(255, 170, 0)"; // 주황
    case slope <= 12.5:
      return "rgb(255, 85, 0)"; // 빨강-주황
    default:
      return "rgb(255, 0, 0)"; // 빨강
  }
};

const OlElevationChart = ({ elevationData = [], onDrag }) => {
  const chartRef = useRef(null); // 차트 참조 생성
  const requestRef = useRef(); // 애니메이션 프레임 참조

  // 마우스 이동 이벤트 핸들러
  const handleMouseMove = useCallback(
    (event) => {
      const chart = chartRef.current;
      if (!chart) return;

      // 기존의 애니메이션 프레임 취소
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }

      // requestAnimationFrame을 사용하여 성능 최적화
      requestRef.current = requestAnimationFrame(() => {
        // 마우스 이벤트에서 가장 가까운 데이터 포인트 찾기
        const elements = chart.getElementsAtEventForMode(
          event,
          "nearest",
          { intersect: false },
          true
        );

        if (elements.length > 0) {
          const index = elements[0].index;
          onDrag?.(index); // onDrag 콜백 호출
        }
      });
    },
    [onDrag]
  );

  // 최대 고도와 최소 고도 계산
  const maxElevation =
    elevationData.length > 0
      ? Math.max(...elevationData.map((d) => d.elevation))
      : -Infinity;
  const minElevation =
    elevationData.length > 0
      ? Math.min(...elevationData.map((d) => d.elevation))
      : Infinity;

  // 각 구간의 경사도 계산
  const slopes = [];
  for (let i = 1; i < elevationData.length; i++) {
    const slope = calculateSlope(elevationData[i - 1], elevationData[i]);
    slopes.push(slope);
  }

  // 차트 데이터 구성
  const data = useMemo(
    () => ({
      labels: elevationData.map((d) => (d.distance / 1000).toFixed(2) * 1), // 거리(km) 라벨
      datasets: [
        {
          label: "",
          data: elevationData.map((d) => d.elevation),
          segment: {
            borderColor: (ctx) => {
              const slope = slopes[ctx.p0DataIndex] ?? 0;
              return getColorForSlope(slope); // 경사도에 따른 색상 설정
            },
          },
          borderWidth: 2,
          pointRadius: 0, // 포인트 표시 안함
          fill: false,
        },
      ],
    }),
    [elevationData, slopes] // 의존성 배열로 성능 최적화
  );

  // 차트 옵션 설정
  const options = useMemo(
    () => ({
      maintainAspectRatio: false, // 반응형 디자인 설정
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: true,
            drawOnChartArea: true,
          },
          ticks: {
            maxTicksLimit: 5, // 최대 눈금 수 제한
            callback: function (value, index) {
              const distance = elevationData[index]?.distance || 0;
              return (distance / 1000).toFixed(2) + " km"; // 거리(km) 표시
            },
          },
        },
        y: {
          title: {
            display: false,
          },
          grid: {
            display: true,
            drawOnChartArea: true,
          },
          ticks: {
            stepSize: 100, // 눈금 간격 설정
            callback: function (value) {
              return value + " m"; // 고도(m) 표시
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false, // 범례 표시 안함
        },
        // 애노테이션 제거 (세로 점선 없음)
        annotation: {
          annotations: {},
        },
        tooltip: {
          intersect: false,
          mode: "index",
          axis: "x",
          callbacks: {
            labelPointStyle: function () {
              return {
                pointStyle: "rect",
                rotation: 0,
              };
            },
            title: function () {
              return "";
            },
            label: function (context) {
              const index = context.dataIndex;
              const currentElevation = elevationData[index]?.elevation || 0;
              const distance = elevationData[index]?.distance || 0;
              const convertedDistance = (distance / 1000).toFixed(2);
              const distanceLabel = `거리: ${convertedDistance} km`;
              const elevationLabel = `고도: ${currentElevation.toFixed(2)} m`;

              if (index > 0) {
                const slope = calculateSlope(
                  elevationData[index - 1],
                  elevationData[index]
                ).toFixed(2);
                const slopeLabel = `경사도: ${slope} %`;

                return [distanceLabel, elevationLabel, slopeLabel];
              }

              return [distanceLabel, elevationLabel];
            },
          },
        },
      },
      animation: {
        duration: 0, // 애니메이션 비활성화로 렌더링 개선
      },
    }),
    [elevationData] // 의존성 배열로 성능 최적화
  );

  return (
    <div onMouseMove={handleMouseMove}>
      <Line
        ref={chartRef} // 차트 참조 설정
        data={data} // 차트 데이터
        options={options} // 차트 옵션
        plugins={[verticalLinePlugin]} // 커스텀 플러그인 등록
      />
    </div>
  );
};

export default OlElevationChart;
