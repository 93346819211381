/*
 * 작성자: 박준우
 * 작성일: 240810
 * 설명:  
    240831 수정
    - localStorage XSS 취약점 이슈로 인해 HttpOnly로 변경
    - 따라서 백엔드에서 자동저장이므로 별도 저장 로직 x
 * 부모 연결:
 * 자식 연결:
 */

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik"; //로그인 폼 상태 관리
import * as Yup from "yup"; //로그인 유효성 검사
import axios from "axios";

export const ModalSignIn = ({ isOpen, onClose, onMove }) => {
  if (!isOpen) return null;

  const validSignin = Yup.object({
    user_id: Yup.string()
      .min(6, "아이디는 6자 이상이어야 합니다.")
      .matches(
        /^[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/,
        "아이디는 영어, 숫자, 특수문자만 포함할 수 있습니다."
      )
      .required("아이디를 입력해주세요."),
    user_pw: Yup.string()
      .matches(/[A-Za-z]/, "비밀번호는 영어를 포함해야 합니다.")
      .matches(/\d/, "비밀번호는 숫자를 포함해야 합니다.")
      .matches(
        /^[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/,
        "비밀번호는 영어, 숫자, 특수문자만 포함할 수 있습니다."
      )
      .required("비밀번호를 입력해주세요."),
  });

  const handleSignin = (values) => {
    const userData = {
      user_id: values.user_id,
      user_pw: values.user_pw,
      rememberMe: values.rememberMe,
    };
    axios
      .post("/signin", userData)
      .then((res) => {
        console.log("ModalSignIn ::: handleSignin :", res.data); // 서버에서 반환되는 데이터를 로그로 출력하여 확인
        if (res.data.success) {
          onClose(); // 팝업 닫기
        } else {
          return alert(res.data.message);
        }
      })
      .catch((err) => {
        // 에러 처리
        const errorMessage = err.response?.data || "알 수 없는 오류 발생";
        alert(errorMessage);
      });
  };

  return (
    <div className="ModalSign">
      <div className="modal-sign__content">
        <div className="modal-sign__top">
          <h2 className="modal-sign__title">로그인</h2>
          <button className="modal-sign__close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-sign__middle">
          <Formik
            initialValues={{ user_id: "", user_pw: "" }}
            validationSchema={validSignin}
            onSubmit={handleSignin}
          >
            <Form className="modal-sign__form">
              <Field
                className="modal-sign__input"
                type="text"
                placeholder="아이디"
                name="user_id"
              />
              <ErrorMessage
                className="modal-sign__error-msg"
                name="user_id"
                component="div"
              />
              <Field
                className="modal-sign__input"
                type="password"
                placeholder="비밀번호"
                name="user_pw"
              />
              <ErrorMessage
                className="modal-sign__error-msg"
                name="user_pw"
                component="div"
              />
              <Field
                type="checkbox"
                name="rememberMe"
                id="rememberMe" //htmlFor 사용위한 id 부여
                className="modal-sign__checkbox"
              />
              <label htmlFor="rememberMe" className="modal-sign__label">
                로그인 상태 유지
              </label>
              <button className="modal-sign__btn" type="submit">
                로그인
              </button>
            </Form>
          </Formik>
        </div>
        <div className="modal-sign__bottom">
          <div className="modal-sign__sign">
            <p className="modal-sign__text">아직 계정이 없으신가요?</p>
            <p className="modal-sign__move" onClick={onMove}>
              회원가입
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
