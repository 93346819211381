import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = ({ isSign, requiredLevel, userLevel }) => {
  console.log(`privateRoute ::: 진입 확인 ${isSign}, userLevel: ${userLevel}`);

  if (isSign && (requiredLevel === undefined || userLevel < requiredLevel)) {
    // 사용자가 로그인되어 있고, 필요한 레벨을 충족하는 경우 접근 허용
    return <Outlet />;
  } else {
    alert("접근 권한이 없습니다.");
    return <Navigate to="/" replace />;
  }
};
