import "../../asset/css/page/ManageDB.css";
import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import manageDB from "../../asset/util/manageDB";

const ManageAccount = () => {
  const [data, setData] = useState([]); // 전체 데이터
  const [filteredData, setFilteredData] = useState([]); // 검색 후 필터된 데이터
  const [loading, setLoading] = useState(true);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // 선택된 행의 인덱스 저장
  const [editingCell, setEditingCell] = useState({
    rowIndex: null,
    columnId: null,
  });

  // 데이터베이스에서 데이터를 가져오는 함수
  const getDB = async () => {
    const condition = { db: "account" };
    try {
      const result = await manageDB({
        type: "select",
        data: condition,
      });

      if (!Array.isArray(result)) {
        throw new Error("Invalid data format received from API");
      }
      setData(result); // 데이터를 상태에 저장
      setFilteredData(result); // 필터된 데이터도 저장
    } catch (error) {
      console.error(
        "데이터베이스에서 데이터를 가져오는 중 오류가 발생했습니다.",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  // 검색 기능
  const handleSearch = () => {
    const searchKeyword = document
      .getElementById("searchInput")
      .value.toLowerCase(); // 검색어를 소문자로 변환
    const filtered = data.filter((row) => {
      return (
        row.user_id.toLowerCase().includes(searchKeyword) || // user_id에서 검색
        row.user_level.toLowerCase().includes(searchKeyword) // user_level에서 검색
      );
    });
    setFilteredData(filtered); // 필터된 데이터 상태 업데이트
  };

  // 선택된 행 삭제
  const handleDeleteRow = async () => {
    if (selectedRowIndex !== null) {
      // 삭제할 데이터
      const selectedData = filteredData[selectedRowIndex];

      // 서버에 삭제 요청
      const dataToSend = {
        db: "account",
        user_id: selectedData.user_id, // 삭제할 데이터의 user_id
      };

      try {
        await manageDB({
          type: "delete",
          data: dataToSend,
        });

        // 삭제 후 UI에서 데이터 제거
        const newData = filteredData.filter(
          (_, index) => index !== selectedRowIndex
        );
        setFilteredData(newData);
        setData(newData); // 원본 데이터에서도 삭제
        setSelectedRowIndex(null); // 선택된 행 초기화
      } catch (error) {
        console.error("행 삭제 중 오류가 발생했습니다.", error);
        alert("행 삭제에 실패했습니다.");
      }
    }
  };

  // 수정된 데이터 저장
  const handleSaveChanges = async () => {
    try {
      // db 필드와 데이터를 하나의 객체로 묶어서 전송
      const dataToSend = {
        db: "account",
        data: filteredData, // 실제 수정된 데이터를 배열로 포함
      };

      await manageDB({
        type: "update",
        data: dataToSend, // 객체를 전송
      });
      alert("변경 사항이 저장되었습니다.");
    } catch (error) {
      console.error("변경 사항을 저장하는 중 오류가 발생했습니다.", error);
      alert("저장 중 오류가 발생했습니다.");
    }
  };

  // 컴포넌트가 마운트될 때 getDB 호출
  useEffect(() => {
    getDB();
  }, []);

  // 컬럼 설정
  const columns = React.useMemo(
    () => [
      {
        Header: "User ID",
        accessor: "user_id",
        editable: false, // 수정 불가능
      },
      {
        Header: "User Level",
        accessor: "user_level",
        editable: true, // 수정 가능
      },
    ],
    []
  );

  // 셀을 더블 클릭했을 때 편집 상태로 전환
  const handleDoubleClick = (rowIndex, columnId) => {
    const column = columns.find((col) => col.accessor === columnId);
    if (column && column.editable) {
      setEditingCell({ rowIndex, columnId });
    }
  };

  // 셀의 값을 변경했을 때 상태 업데이트
  const handleInputChange = (e, rowIndex, columnId) => {
    const newData = [...filteredData];
    newData[rowIndex][columnId] = e.target.value;
    setFilteredData(newData);
  };

  // 셀 편집이 완료되었을 때 상태 초기화
  const handleInputBlur = () => {
    setEditingCell({ rowIndex: null, columnId: null });
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: filteredData, // 필터된 데이터를 테이블에 전달
    });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="table-container">
      <h2>Manage Accounts</h2>
      <div className="home__search-box">
        <div className="home__field">
          <input
            type="text"
            id="searchInput"
            className="home__input"
            placeholder="검색"
          />
          <button className="home__btn-search" onClick={handleSearch}>
            검색
          </button>
        </div>
      </div>
      {filteredData.length === 0 ? (
        <div>데이터가 없습니다.</div>
      ) : (
        <div>
          <button
            onClick={handleDeleteRow}
            disabled={selectedRowIndex === null}
          >
            삭제
          </button>
          <button onClick={handleSaveChanges}>수정 저장</button>
          <table {...getTableProps()} border="1">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => setSelectedRowIndex(index)} // 선택된 행 설정
                    className={index === selectedRowIndex ? "selected-row" : ""} // 선택된 행에 클래스 추가
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          onDoubleClick={() =>
                            handleDoubleClick(index, cell.column.id)
                          }
                        >
                          {editingCell.rowIndex === index &&
                          editingCell.columnId === cell.column.id &&
                          cell.column.editable ? (
                            <input
                              value={cell.value}
                              onChange={(e) =>
                                handleInputChange(e, index, cell.column.id)
                              }
                              onBlur={handleInputBlur}
                              autoFocus
                            />
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ManageAccount;
