/*
 * 작성자: 박준우
 * 작성일: 241115
 * 설명: likeStatus 및 dislikeStatus 객체를 사용해서
 *       각 댓글 및 답글의 ID를 키로 사용하여 개별 좋아요/싫어요 상태를 관리
 *      handleLikeDislike 함수에서 상태 업데이트:
 *      특정 commentId에 대한 좋아요 또는 싫어요 상태를 관리하여 다른 댓글이나 답글의 상태에 영향을 주지 않습니다
 * 부모 연결:
 * 자식 연결:
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useAuth } from "../../asset/util/authContext.js";
import manageComment from "../../asset/util/manageComment.js";
import {
  AiOutlineLike,
  AiFillLike,
  AiOutlineDislike,
  AiFillDislike,
} from "react-icons/ai";
import "../../asset/css/component/DivComment1.css";

const DivComment1 = ({ postId }) => {
  const { isSign, userId, userLevel } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [editCommentId, setEditCommentId] = useState(null);
  const [replyFormIndex, setReplyFormIndex] = useState(null);
  const [editReplyId, setEditReplyId] = useState(null);
  const [likeStatus, setLikeStatus] = useState({});
  const [dislikeStatus, setDislikeStatus] = useState({});

  useEffect(() => {
    if (postId) {
      setIsLoading(true);
      getComments();
    }
  }, [postId]);

  const handleLikeDislike = async (commentId, type, isReply = false) => {
    if (!isSign) {
      return alert("로그인 후 이용할 수 있습니다.");
    }

    const isLiked = likeStatus[commentId] || false;
    const isDisliked = dislikeStatus[commentId] || false;

    const data = {
      db: type,
      comment_id: commentId,
      user_id: userId,
    };

    try {
      let response;

      if (type === "like") {
        // 좋아요 처리
        if (isLiked) {
          // 좋아요 취소
          response = await axios.delete("/evaluate", { params: data });
          setLikeStatus((prev) => ({ ...prev, [commentId]: false }));
        } else {
          // 싫어요 상태를 취소
          if (isDisliked) {
            await axios.delete("/evaluate", {
              params: { db: "dislike", comment_id: commentId, user_id: userId },
            });
            setDislikeStatus((prev) => ({ ...prev, [commentId]: false }));
          }
          // 좋아요 추가
          response = await axios.post("/evaluate", data);
          setLikeStatus((prev) => ({ ...prev, [commentId]: true }));
        }
      } else if (type === "dislike") {
        // 싫어요 처리
        if (isDisliked) {
          // 싫어요 취소
          response = await axios.delete("/evaluate", { params: data });
          setDislikeStatus((prev) => ({ ...prev, [commentId]: false }));
        } else {
          // 좋아요 상태를 취소
          if (isLiked) {
            await axios.delete("/evaluate", {
              params: { db: "like", comment_id: commentId, user_id: userId },
            });
            setLikeStatus((prev) => ({ ...prev, [commentId]: false }));
          }
          // 싫어요 추가
          response = await axios.post("/evaluate", data);
          setDislikeStatus((prev) => ({ ...prev, [commentId]: true }));
        }
      }

      return response?.data;
    } catch (error) {
      console.error("evaluate Error:", error);
      throw error;
    }
  };

  const handleEdit = (comment) => {
    if (comment) {
      if (comment.user_id === userId) {
        setEditCommentId(comment.comment_id);
      } else {
        alert("본인이 쓴 글만 수정이 가능합니다.");
      }
    } else {
      console.error("댓글을 찾을 수 없습니다.");
    }
  };

  const toggleReplyEdit = (parentId, commentId) => {
    const parent = comments.find((comment) => comment.comment_id === parentId);

    let comment;
    if (parent) {
      comment = parent.replies.find((reply) => reply.comment_id === commentId);
    } else {
      return console.error("원 댓글을 찾을 수 없습니다.");
    }

    if (comment) {
      if (comment.user_id === userId) {
        setEditReplyId(commentId);
      } else {
        alert("본인이 쓴 답글만 수정이 가능합니다.");
      }
    } else {
      console.error("답글을 찾을 수 없습니다.");
    }
  };

  const getComments = async () => {
    try {
      const data = { post_id: postId };
      const result = await manageComment({
        type: "select",
        data: data,
      });

      const commentsList = result.rows.filter(
        (comment) => comment.parent_id === null
      );
      const repliesList = result.rows.filter(
        (reply) => reply.parent_id !== null
      );

      // 초기 likeStatus와 dislikeStatus 설정
      const initialLikeStatus = {};
      const initialDislikeStatus = {};

      const commentsWithReplies = commentsList.map((comment) => {
        // 댓글의 초기 좋아요 및 싫어요 상태 설정
        initialLikeStatus[comment.comment_id] = comment.user_liked || false;
        initialDislikeStatus[comment.comment_id] =
          comment.user_disliked || false;

        return {
          ...comment,
          replies: repliesList.filter((reply) => {
            // 답글의 초기 좋아요 및 싫어요 상태 설정
            initialLikeStatus[reply.comment_id] = reply.user_liked || false;
            initialDislikeStatus[reply.comment_id] =
              reply.user_disliked || false;

            return reply.parent_id === comment.comment_id;
          }),
        };
      });

      // 상태 업데이트
      setComments(commentsWithReplies);
      setLikeStatus(initialLikeStatus);
      setDislikeStatus(initialDislikeStatus);
    } catch (error) {
      console.error("댓글 불러오기 실패:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInsert = async (parent_id = null) => {
    if (!isSign) {
      return alert("로그인 후 작성할 수 있습니다.");
    }

    const commentContent = parent_id
      ? document.getElementById(`replyContent-${parent_id}`).value
      : document.getElementById("commentContent").value;

    const data = {
      post_id: postId,
      user_id: userId,
      comment: commentContent,
      parent_id: parent_id || null,
    };

    setIsLoading(true);
    try {
      await manageComment({
        type: "insert",
        data: data,
      });

      if (parent_id) {
        document.getElementById(`replyContent-${parent_id}`).value = "";
        setReplyFormIndex(null);
      } else {
        document.getElementById("commentContent").value = "";
      }
      await getComments();
    } catch (error) {
      console.error("등록 실패:", error);
    }
  };

  const handleUpdate = async (comment) => {
    const newContent = comment.parent_id
      ? document.getElementById(`reply-${comment.comment_id}`).value
      : document.getElementById(`comment-${comment.comment_id}`).value;

    const data = {
      user_id: comment.user_id,
      comment_id: comment.comment_id,
      comment: newContent,
    };

    setIsLoading(true);
    try {
      await manageComment({
        type: "update",
        data: data,
      });
      await getComments();
    } catch (error) {
      console.error("수정 실패:", error);
    } finally {
      setEditCommentId(null);
      setEditReplyId(null);
    }
  };

  const handleDelete = async (comment) => {
    if (isSign) {
      if (comment.user_id === userId || userLevel < 5) {
        const data = {
          user_id: comment.user_id,
          comment_id: comment.comment_id,
        };

        setIsLoading(true);
        try {
          await manageComment({
            type: "delete",
            data: data,
          });
          await getComments();
        } catch (error) {
          console.error("삭제 실패:", error);
        }
      } else {
        alert("본인이 쓴 글만 삭제가 가능합니다.");
      }
    } else {
      alert("로그인 후 이용하세요.");
    }
  };

  const toggleReplyForm = (index) => {
    setReplyFormIndex(replyFormIndex === index ? null : index);
  };

  return (
    <div className="DivComment1">
      {isLoading && (
        <div className="loading">
          <div className="spinner"></div>
          <div>댓글 갱신 중...</div>
        </div>
      )}
      <h4 className="comments__title">댓글</h4>
      <div className="comments__list">
        {comments.map((comment, index) => (
          <div key={comment.comment_id} className="comment">
            <span className="comment__user">{comment.user_id}</span>
            <span className="comment__date">
              {format(new Date(comment.created_at), "yyyy-MM-dd")}
            </span>
            {editCommentId === comment.comment_id ? (
              <input
                type="text"
                defaultValue={comment.comment}
                id={`comment-${comment.comment_id}`}
                className="comment__title-input"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate(comment);
                  }
                }}
              />
            ) : (
              <div className="comment__content">{comment.comment}</div>
            )}
            <div className="comment__buttons">
              <button
                className="comment__button comment__button--like"
                onClick={() => handleLikeDislike(comment.comment_id, "like")}
              >
                {likeStatus[comment.comment_id] ? (
                  <AiFillLike />
                ) : (
                  <AiOutlineLike />
                )}
              </button>
              <button
                className="comment__button comment__button--dislike"
                onClick={() => handleLikeDislike(comment.comment_id, "dislike")}
              >
                {dislikeStatus[comment.comment_id] ? (
                  <AiFillDislike />
                ) : (
                  <AiOutlineDislike />
                )}
              </button>
              {isSign && (comment.user_id === userId || userLevel < 5) && (
                <>
                  {editCommentId === comment.comment_id ? (
                    <button
                      className="comment__button comment__button--save"
                      onClick={() => handleUpdate(comment)}
                    >
                      저장
                    </button>
                  ) : (
                    <>
                      <button
                        className="comment__button comment__button--update"
                        onClick={() => handleEdit(comment)}
                      >
                        수정
                      </button>
                      <button
                        className="comment__button comment__button--delete"
                        onClick={() => handleDelete(comment)}
                      >
                        삭제
                      </button>
                    </>
                  )}
                </>
              )}
            </div>

            <button
              className="comment__reply-button"
              onClick={() => toggleReplyForm(index)}
            >
              답글 달기
            </button>

            {replyFormIndex === index && (
              <div className="comment__reply-form">
                <input
                  type="text"
                  id={`replyContent-${comment.comment_id}`}
                  placeholder="답글을 입력하세요"
                  className="comment__reply-input-text"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleInsert(comment.comment_id);
                    }
                  }}
                />
                <button
                  className="btn btn--create"
                  onClick={() => handleInsert(comment.comment_id)}
                >
                  답글 등록
                </button>
              </div>
            )}

            <div className="comment__replies">
              {comment.replies &&
                comment.replies.map((reply) => (
                  <div key={reply.comment_id} className="reply">
                    <span className="reply__user">{reply.user_id}</span>
                    <span className="reply__date">
                      {format(new Date(reply.created_at), "yyyy-MM-dd")}
                    </span>
                    {editReplyId === reply.comment_id ? (
                      <input
                        type="text"
                        defaultValue={reply.comment}
                        id={`reply-${reply.comment_id}`}
                        className="reply__title-input"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate(reply);
                          }
                        }}
                      />
                    ) : (
                      <div className="reply__content">{reply.comment}</div>
                    )}
                    <div className="reply__buttons">
                      <button
                        className="reply__button reply__button--like"
                        onClick={() =>
                          handleLikeDislike(reply.comment_id, "like", true)
                        }
                      >
                        {likeStatus[reply.comment_id] ? (
                          <AiFillLike />
                        ) : (
                          <AiOutlineLike />
                        )}
                      </button>
                      <button
                        className="reply__button reply__button--dislike"
                        onClick={() =>
                          handleLikeDislike(reply.comment_id, "dislike", true)
                        }
                      >
                        {dislikeStatus[reply.comment_id] ? (
                          <AiFillDislike />
                        ) : (
                          <AiOutlineDislike />
                        )}
                      </button>
                      {isSign &&
                        (reply.user_id === userId || userLevel < 5) && (
                          <>
                            {editReplyId === reply.comment_id ? (
                              <button
                                className="reply__button reply__button--save"
                                onClick={() => handleUpdate(reply)}
                              >
                                저장
                              </button>
                            ) : (
                              <>
                                <button
                                  className="reply__button reply__button--update"
                                  onClick={() =>
                                    toggleReplyEdit(
                                      comment.comment_id,
                                      reply.comment_id
                                    )
                                  }
                                >
                                  수정
                                </button>
                                <button
                                  className="reply__button reply__button--delete"
                                  onClick={() => handleDelete(reply)}
                                >
                                  삭제
                                </button>
                              </>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>

      <div className="comments__input">
        <input
          type="text"
          id="commentContent"
          placeholder="댓글을 입력하세요"
          className="comment__input-text"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleInsert();
            }
          }}
        />
        <button className="btn btn--create" onClick={() => handleInsert()}>
          댓글 등록
        </button>
      </div>
    </div>
  );
};

export default DivComment1;
