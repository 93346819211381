/*
 * 작성자: 박준우
 * 작성일: 240901 
 * 설명: NotFound 생성
*/
import "./asset/css/NotFound.css";

import React from 'react';
import { Link } from 'react-router-dom';


function NotFound() {
  return (
    <div className="not-found__container">
      <h1 className="not-found__title">404</h1>
      <p className="not-found__text">접근 경로에 문제가 있어요</p>
      <Link to="/" className="not-found__link">홈으로 돌아가기</Link>
    </div>
  );
}

export default NotFound;
