/*
 * 작성자: 박준우
 * 작성일: 240902
 * 설명: (.env) 적용하려면 clone 후 빌드 필수
 */
import { useEffect, useRef, useState } from "react";
import Map from "ol/Map.js";
import View from "ol/View.js";
import XYZ from "ol/source/XYZ.js";
import Point from "ol/geom/Point.js";
import Feature from "ol/Feature.js";
import { GeoJSON } from "ol/format.js";
import VectorSource from "ol/source/Vector.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style.js";
import { get as getProjection, transform } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
import { defaults as defaultInteractions } from "ol/interaction.js";
import { getDistance } from "ol/sphere";
import Collection from "ol/Collection";
import { Snap, Translate } from "ol/interaction";

const newProj = getProjection("EPSG:3857");

const useVworld = ({
  file,
  basicControls = {},
  customControls = [],
  basicInteractions = {},
  customInteractions = [],
  onDrag,
  currentIndex = 0,
}) => {
  const mapRef = useRef(null);
  const indexRef = useRef([]); // 좌표를 저장할 useRef
  const markerRef = useRef(null); // 마커를 참조할 useRef
  const [mapInstance, setMapInstance] = useState(null);
  const [elevationData, setElevationData] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalAscent, setTotalAscent] = useState(0);

  useEffect(() => {
    if (!mapInstance && mapRef.current) {
      const wmts_tile = new TileLayer({
        name: "WMTS 배경지도",
        source: new XYZ({
          url: `/vworld/wmts/{z}/{y}/{x}`,
          // url: `https://api.vworld.kr/req/wmts/1.0.0/${process.env.REACT_APP_VWORLD_KEY}/Base/{z}/{y}/{x}.png`,
          zIndex: 1,
          preload: Infinity,
        }),
      });

      const ol_vworld = new Map({
        target: mapRef.current,
        layers: [wmts_tile],
        view: new View({
          center: transform([127.6167, 36.5667], "EPSG:4326", "EPSG:3857"),
          zoom: 7,
          minZoom: 6,
          maxZoom: 18,
          projection: newProj,
        }),
        controls: defaultControls(basicControls).extend(customControls),
        interactions:
          defaultInteractions(basicInteractions).extend(customInteractions),
      });

      setMapInstance(ol_vworld);
    }
  }, [
    mapRef,
    mapInstance,
    basicControls,
    customControls,
    basicInteractions,
    customInteractions,
  ]);

  useEffect(() => {
    console.log("useVworld ::: useEffect", file);
    if (file && mapInstance) {
      const style = {
        LineString: new Style({
          stroke: new Stroke({
            color: "#f00",
            width: 3,
          }),
        }),
        MultiLineString: new Style({
          stroke: new Stroke({
            color: "#0f0",
            width: 3,
          }),
        }),
      };

      const filesArray = Array.isArray(file) ? file : [file];

      filesArray.forEach((fileItem) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const geoJSONFormat = new GeoJSON();
          const features = geoJSONFormat.readFeatures(e.target.result, {
            featureProjection: "EPSG:3857",
          });

          const vectorSource = new VectorSource({
            features: features,
          });

          // 시작점과 끝점 생성
          const startPoint = new Feature({
            geometry: new Point(features[0].getGeometry().getFirstCoordinate()),
          });
          const endPoint = new Feature({
            geometry: new Point(features[0].getGeometry().getLastCoordinate()),
          });

          // 시작점과 끝점의 스타일 설정
          const startStyle = new Style({
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({ color: "red" }), // 시작점 색상: 빨간색
              stroke: new Stroke({
                color: "white",
                width: 2,
              }),
            }),
            text: new Text({
              text: "출발",
              font: "bold 12px sans-serif",
              fill: new Fill({ color: "black" }), // 텍스트 색상: 검정색
              offsetX: -23,
              backgroundFill: new Fill({ color: "rgba(255, 255, 255, 0.8)" }), // 텍스트 배경 색상: 반투명 흰색
              padding: [0.5, 0.5, 0.5, 0.5], // 텍스트와 배경 간의 여백
            }),
          });

          const endStyle = new Style({
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({ color: "blue" }), // 끝점 색상: 파란색
              stroke: new Stroke({
                color: "white",
                width: 2,
              }),
            }),
            text: new Text({
              text: "도착",
              font: "bold 12px sans-serif",
              fill: new Fill({ color: "black" }), // 텍스트 색상: 검정색
              offsetX: 23,
              backgroundFill: new Fill({ color: "rgba(255, 255, 255, 0.8)" }), // 텍스트 배경 색상: 반투명 흰색
              padding: [0.5, 0.5, 0.5, 0.5], // 텍스트와 배경 간의 여백
            }),
          });

          startPoint.setStyle(startStyle);
          endPoint.setStyle(endStyle);

          vectorSource.addFeatures([startPoint, endPoint]);

          const file_vector = new VectorLayer({
            source: vectorSource,
            style: function (feature) {
              return style[feature.getGeometry().getType()] || undefined;
            },
          });

          mapInstance.addLayer(file_vector);

          const extent = vectorSource.getExtent();
          mapInstance.getView().fit(extent, {
            padding: [30, 30, 30, 30],
          });

          const elevationData = extractElevationData(features);
          setTotalDistance(elevationData.totalDist);
          setTotalAscent(elevationData.totalAsc);
          setElevationData(elevationData.data);

          // 마커를 경로의 시작점에 추가
          const marker = new Feature({
            geometry: new Point(features[0].getGeometry().getFirstCoordinate()),
          });
          markerRef.current = marker; // 마커를 ref에 저장

          const markerStyle = new Style({
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({ color: "green" }), // 마커 색상
              stroke: new Stroke({
                color: "white",
                width: 2,
              }),
            }),
          });
          marker.setStyle(markerStyle);

          const markerLayer = new VectorLayer({
            source: new VectorSource({
              features: [marker],
            }),
          });

          mapInstance.addLayer(markerLayer);

          // // Translate 인터랙션 추가 (마커를 드래그할 수 있도록 설정)
          // const translate = new Translate({
          //   features: new Collection([marker]),
          // });
          // mapInstance.addInteraction(translate);

          // // Snap 인터랙션 추가 (경로 위에서만 드래그 가능하도록 설정)
          // const snap = new Snap({ source: vectorSource });
          // mapInstance.addInteraction(snap);

          // // Translate 인터랙션의 이벤트 리스너 추가
          // const snapToPath = (event) => {
          //   const feature = event.features.item(0);
          //   const geometry = feature.getGeometry();
          //   const closestFeature = vectorSource.getClosestFeatureToCoordinate(
          //     geometry.getCoordinates()
          //   );

          //   if (closestFeature) {
          //     const closestPoint = closestFeature
          //       .getGeometry()
          //       .getClosestPoint(geometry.getCoordinates());
          //     feature.setGeometry(new Point(closestPoint)); // 마커를 경로의 가장 가까운 지점으로 이동

          //     // indexRef에 저장된 좌표와 closestPoint 비교하여 가장 근접한 인덱스 찾기
          //     const closestCoordinateIndex = indexRef.current.reduce(
          //       (closestIndex, coord, index) => {
          //         const distance = getDistance(
          //           transform(coord, "EPSG:3857", "EPSG:4326"),
          //           transform(closestPoint, "EPSG:3857", "EPSG:4326")
          //         );

          //         if (distance < closestIndex.minDistance) {
          //           return { index, minDistance: distance };
          //         } else {
          //           return closestIndex;
          //         }
          //       },
          //       { index: -1, minDistance: Infinity }
          //     ).index;

          //     onDrag(closestCoordinateIndex);
          //   }
          // };

          // translate.on("translating", snapToPath); // 드래그 중 경로 위로 스냅
          // translate.on("translateend", snapToPath); // 드래그 종료 시 경로 위로 스냅
        };
        reader.readAsText(fileItem);
      });
    }
  }, [file, mapInstance]);

  useEffect(() => {
    // currentIndex가 변경될 때 마커 위치 업데이트
    if (markerRef.current && indexRef.current[currentIndex]) {
      const newCoordinates = indexRef.current[currentIndex];
      markerRef.current.setGeometry(new Point(newCoordinates));
    }
  }, [currentIndex]);

  const extractElevationData = (features) => {
    let totalDist = 0;
    let totalAsc = 0;

    const elevationData = features.flatMap((feature) => {
      const geometry = feature.getGeometry();
      if (geometry.getType() === "LineString") {
        const coordinates = geometry.getCoordinates();
        indexRef.current.push(...coordinates); // 모든 좌표 저장 (인덱스 추출용)

        return coordinates.map((coord, index) => {
          if (index > 0) {
            const previousCoord = coordinates[index - 1];
            const transformedPrevCoord = transform(
              previousCoord,
              "EPSG:3857",
              "EPSG:4326"
            );
            const transformedCoord = transform(coord, "EPSG:3857", "EPSG:4326");
            const distance = getDistance(
              transformedPrevCoord,
              transformedCoord
            );
            totalDist += distance;

            const elevationDiff = coord[2] - previousCoord[2];
            if (elevationDiff > 0) {
              totalAsc += elevationDiff;
            }
          }
          return {
            distance: totalDist,
            ascent: totalAsc,
            elevation: coord[2] || 0,
          };
        });
      }
      return [];
    });
    return { data: elevationData, totalDist, totalAsc };
  };

  return { mapRef, elevationData, totalDistance, totalAscent };
};

export default useVworld;
