/*
 * 작성자:
 * 작성일:
 * 설명: 네트워크 통신을 줄이기 위해 contextAPI 사용
 * 부모 연결:
 * 자식 연결:
 */
import React, { createContext, useContext, useState, useEffect } from "react";
import authInterceptor from "./authIntercepter";
import AuthContextType from "./authContextType";
import axios from "axios";
import styled from "styled-components";

// AuthContext 생성
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isSign, setSign] = useState(false);
  const [userId, setUserId] = useState("");
  const [userNick, setUserNick] = useState("");
  const [userLevel, setUserLevel] = useState(null);
  const [userFilePath, setUserFilePath] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // await new Promise((resolve) => setTimeout(resolve, 10000)); // 시간 지연 (타이핑 확인용)
        const response = await authInterceptor.post("/auth/user");
        console.log("authContext ::: checkAuth ", response);
        if (response.data?.user_id) {
          setSign(true);
          setUserId(response.data.user_id);
          setUserNick(response.data.user_nick);
          setUserLevel(response.data.user_level);
          setUserFilePath(response.data.user_file_path);
        } else {
          setUserId("");
          setUserNick("");
          setSign(false);
          setUserLevel(null);
          setUserFilePath(null);
        }
      } catch (error) {
        console.error("권한 확인 중 오류 발생(= 로그인 x):", error);
        setError(error);
        setUserId("");
        setUserNick("");
        setSign(false);
        setUserLevel(null);
        setUserFilePath(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (loading) {
    // 이렇게 해야 홈화면 데이터 이중 요청 방지됨if (loading)
    return (
      <S.Wrapper>
        <S.Image src="/logo.svg" alt="Loading" />
        <AuthContextType />
      </S.Wrapper>
    );
  }

  const signOut = async () => {
    try {
      const response = await axios.post("/signout");
      if (response.status === 200) {
        setSign(false);
        setUserId("");
        setUserLevel(null);
      } else {
        console.error("로그아웃 실패:", response.status);
      }
      console.log("authContext ::: signOut 처리 완료");
    } catch (error) {
      console.error("로그아웃 중 오류 발생:", error);
      setError(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isSign,
        userId,
        userNick,
        userLevel,
        userFilePath,
        loading,
        error,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// useAuth 훅
export const useAuth = () => {
  return useContext(AuthContext);
};

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #0484fc; /* 배경색 설정 */
  `,
  Image: styled.img`
    width: 200px; /* 이미지 크기 설정 */
    height: auto;
    margin-bottom: 20px;
  `,
};
