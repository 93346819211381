/*
 * 작성자:
 * 작성일:
 * 설명:
 * 부모 연결:
 * 자식 연결:
 */

import React, { useState, useCallback } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { encode, decode } from "../../asset/util/encodeName.js";
import "../../asset/css/tool/dragAndDrop.css"; // Modal 컴포넌트용 CSS

// 기본 prop 값 설정
const DEFAULT_ALLOWED_EXTENSIONS = [];
const DEFAULT_MAX_FILES = 3;

export const DragAndDrop = ({
  onFilesUpload,
  maxFiles = DEFAULT_MAX_FILES,
  allowedExtensions = DEFAULT_ALLOWED_EXTENSIONS,
}) => {
  const [files, setFiles] = useState([]); // 파일 목록 관리
  const [fileInfo, setFileInfo] = useState([]); // 파일 업로드 후 리턴 목록 관리
  const allowedExtensionsStr = allowedExtensions.join(", ");

  const onDrop = useCallback(
    (acceptedFiles) => {
      // 파일 개수 체크
      const allFiles = [...files, ...acceptedFiles];
      if (allFiles.length > maxFiles) {
        alert(`파일은 최대 ${maxFiles}개까지 업로드할 수 있습니다.`);
        return;
      }

      // 허용되지 않는 파일 필터링
      const invalidFiles = acceptedFiles.filter((file) => {
        const extension = file.name.split(".").pop().toLowerCase(); // 확장자 추출
        return !allowedExtensions.includes(extension);
      });

      if (invalidFiles.length > 0) {
        alert(
          `허용되지 않는 파일 형식입니다. 허용된 형식: ${allowedExtensionsStr}`
        );
        return;
      }

      // 허용된 파일만 상태에 추가
      const validFiles = acceptedFiles.filter((file) => {
        const extension = file.name.split(".").pop().toLowerCase(); // 확장자 추출
        return allowedExtensions.includes(extension);
      });

      setFiles((prevFiles) => [...prevFiles, ...validFiles]);

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        // 파일 이름과 확장자 분리
        const fileName = file.name;
        const dotIndex = fileName.lastIndexOf(".");
        const nameWithoutExt = fileName.substring(0, dotIndex);
        const ext = fileName.substring(dotIndex);

        // 파일 이름을 인코딩한 후 확장자와 다시 합치기
        const encodedFileName = encode(nameWithoutExt) + ext;

        formData.append("file", file, encodedFileName); // 인코딩된 이름을 사용하여 파일 추가
        return axios
          .post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // 서버에서 받은 파일 이름을 디코딩
            const originalFileName = response.data.file.originalname;
            const dotIndex = originalFileName.lastIndexOf(".");
            const encodedName = originalFileName.substring(0, dotIndex);
            const ext = originalFileName.substring(dotIndex);

            // 이름 부분만 디코딩하고 확장자와 합치기
            const decodedFileName = decode(encodedName) + ext;

            response.data.file.originalname = decodedFileName;
            return response.data.file; // 서버에서 받은 파일 정보
          })
          .catch((error) => {
            console.error("파일 업로드 실패:", error);
            return null;
          });
      });

      Promise.all(uploadPromises).then((fileInfos) => {
        // 파일 업로드 후 받은 파일 정보를 부모 컴포넌트로 전달
        const filteredFileInfos = fileInfos.filter((info) => info !== null);

        setFileInfo((prevFileInfo) => {
          const newFileInfo = [...prevFileInfo, ...filteredFileInfos];
          onFilesUpload(newFileInfo);
          return newFileInfo;
        });
      });
    },
    [files, maxFiles, allowedExtensions, allowedExtensionsStr, onFilesUpload]
  );

  const removeFile = useCallback(
    (fileName) => {
      const matchingFile = fileInfo.find(
        (file) => file.originalname === fileName
      );
      if (matchingFile) {
        const fileToRemove = matchingFile.filename;

        const newFiles = files.filter((file) => file.name !== fileName);
        setFiles(newFiles);

        const data = {
          fileName: fileToRemove,
        };

        axios
          .delete("/file", { params: data })
          .then((response) => {
            console.log("dragAndDrop ::: removeFile 성공:", response);
            setFileInfo((prevFileInfo) => {
              const newFileInfo = prevFileInfo.filter(
                (file) => file.originalname !== fileName
              );
              onFilesUpload(newFileInfo);
              return newFileInfo;
            });
          })
          .catch((error) => {
            console.error("파일 삭제 실패:", error);
          });
      } else {
        console.error("일치하는 파일을 찾을 수 없습니다.");
      }
    },
    [files, fileInfo, onFilesUpload]
  );

  const handleRemove = (e, fileName) => {
    e.preventDefault(); // 폼 제출 방지
    e.stopPropagation(); // 이벤트 전달 방지
    removeFile(fileName);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="DragAndDrop" {...getRootProps()}>
      <input className="dragndrop__input" {...getInputProps()} />
      <p className="dragndrop__text">
        파일을 이곳에 끌어다 놓거나 클릭하여 파일을 선택하세요. 첨부 가능한
        확장자 : {allowedExtensionsStr}
      </p>
      <ul className="dragndrop__list">
        {files.map((file) => (
          <li key={file.name} className="dragndrop__file">
            {file.name}
            <button
              onClick={(e) => handleRemove(e, file.name)}
              className="dragndrop__btn--remove"
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
