import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { useAuth } from "../../asset/util/authContext.js";
import {
  AiOutlineLike,
  AiFillLike,
  AiOutlineDislike,
  AiFillDislike,
} from "react-icons/ai";
import manageBoard from "../../asset/util/manageBoard.js";
import DivComment1 from "../component/DivComment1.js";
import { DivOlMap } from "../tool/olVworldMap.js"; // 맵 툴
import FileList from "../tool/fileList.js";
import "../../asset/css/component/ModalPostMap_View.css"; // Modal 컴포넌트용 CSS

export const ModalPostMap_View = ({ isOpen, onClose, post, files, map }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="ModalPost2">
      <EditablePostItem
        key={post.post_id}
        post={post}
        fileList={files}
        mapFile={map}
        onClose={onClose}
      />
    </div>
  );
};

const EditablePostItem = ({ post, fileList, mapFile, onClose }) => {
  const navigate = useNavigate();
  const { isSign, userId, userLevel } = useAuth();
  const [isEdit, setEdit] = useState(false);
  const [likeStatus, setLikeStatus] = useState(false);
  const [dislikeStatus, setDislikeStatus] = useState(false);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 post의 초기 상태 반영
    if (post) {
      setLikeStatus(post.user_liked || false);
      setDislikeStatus(post.user_disliked || false);
    }
  }, [post]);

  const handleLikeDislike = async (type) => {
    if (!isSign) {
      return alert("로그인 후 이용할 수 있습니다.");
    }

    const isLiked = likeStatus;
    const isDisliked = dislikeStatus;
    const data = {
      db: type,
      post_id: post.post_id,
      user_id: userId,
    };

    try {
      let response;
      if (type === "like") {
        if (isLiked) {
          // 좋아요 취소
          response = await axios.delete("/evaluate", { params: data });
          setLikeStatus(false);
        } else {
          // 싫어요 취소
          if (isDisliked) {
            await axios.delete("/evaluate", {
              params: { db: "dislike", post_id: post.post_id, user_id: userId },
            });
            setDislikeStatus(false);
          }
          // 좋아요 추가
          response = await axios.post("/evaluate", data);
          setLikeStatus(true);
        }
      } else if (type === "dislike") {
        if (isDisliked) {
          // 싫어요 취소
          response = await axios.delete("/evaluate", { params: data });
          setDislikeStatus(false);
        } else {
          // 좋아요 취소
          if (isLiked) {
            await axios.delete("/evaluate", {
              params: { db: "like", post_id: post.post_id, user_id: userId },
            });
            setLikeStatus(false);
          }
          // 싫어요 추가
          response = await axios.post("/evaluate", data);
          setDislikeStatus(true);
        }
      }

      return response.data;
    } catch (error) {
      console.error("evaluate Error:", error);
      throw error;
    }
  };

  const handleEdit = () => {
    if (isSign) {
      if (post.user_id === userId || userLevel < 5) {
        setEdit(true);
      } else {
        alert("본인이 쓴 글만 수정이 가능합니다.");
      }
    } else {
      alert("로그인 후 이용하세요.");
    }
  };

  const handleUpdate = () => {
    if (isSign && (post.user_id === userId || userLevel < 5)) {
      try {
        const title = document.getElementById("postTitle").value;
        const content = document.getElementById("postContent").value;
        const data = {
          category: 1,
          post_id: post.post_id,
          title: title,
          content: content,
          user_id: post.user_id,
        };

        // 게시글 업데이트 API 호출
        manageBoard({
          type: "update",
          data: data,
        });
        navigate("/");
        window.location.reload();
      } catch (err) {
        console.error("ModalPostMap_View ::: 수정실패 : ", err);
      }
    } else {
      alert("본인이 쓴 글만 삭제가 가능합니다.");
    }
  };

  const handleDelete = () => {
    if (isSign && (post.user_id === userId || userLevel < 5)) {
      try {
        const data = {
          category: 1,
          post_id: post.post_id,
          user_id: post.user_id,
        };
        manageBoard({
          type: "delete",
          data: data,
        });
        navigate("/");
        window.location.reload();
      } catch (err) {
        console.error("ModalPostMap_View ::: 삭제실패 : ", err);
      }
    } else {
      alert("본인이 쓴 글만 삭제가 가능합니다.");
    }
  };

  const copyLinkToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("링크가 복사되었습니다!");
      })
      .catch((err) => {
        console.error("링크 복사 실패:", err);
      });
  };

  return (
    <div className="ModalPostMap_View">
      <div className="modal__content">
        <div className="modal__left">
          <div className="modal__header">
            <DivOlMap file={mapFile} />
          </div>
          <div className="modal__body">
            <FileList files={fileList} />
            <div className="link-copy__section">
              <ul className="link-copy__items">
                <li className="link-copy__item">
                  <input
                    type="text"
                    value={window.location.href}
                    readOnly
                    className="link-copy__input"
                  />
                  <button
                    className="link-copy__button"
                    onClick={copyLinkToClipboard}
                  >
                    링크 복사
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="modal__right">
          <button className="modal__close" onClick={onClose}>
            &times;
          </button>
          {isEdit ? (
            <div className="modal__board">
              <input
                type="text"
                id="postTitle"
                defaultValue={post.title}
                className="post__title-input"
              />
              <textarea
                id="postContent"
                defaultValue={post.content}
                className="post__content-input"
              />
            </div>
          ) : (
            <div className="modal__board">
              <div className="post__title">{post.title}</div>
              <div className="post__content">{post.content}</div>
            </div>
          )}
          <div className="modal__footer">
            <span className="post__author">
              작성자: {post.user_id || "익명"}
            </span>
            <span className="post__date">
              등록일: {format(new Date(post.created_at), "yyyy-MM-dd")}
            </span>
            <span className="post__date">
              수정일: {format(new Date(post.updated_at), "yyyy-MM-dd")}
            </span>
            <div className="post__actions">
              {isEdit ? (
                <button
                  id="btnSave"
                  className="btn btn--save"
                  onClick={handleUpdate}
                >
                  저장
                </button>
              ) : (
                <>
                  <button
                    id="btnUpdate"
                    className="btn btn--update"
                    onClick={handleEdit}
                  >
                    수정
                  </button>
                  <button
                    id="btnDelete"
                    className="btn btn--delete"
                    onClick={handleDelete}
                  >
                    삭제
                  </button>
                </>
              )}
              <button
                className="post__button post__button--like"
                onClick={() => handleLikeDislike("like")}
              >
                {likeStatus ? <AiFillLike /> : <AiOutlineLike />}
              </button>
              <button
                className="post__button post__button--dislike"
                onClick={() => handleLikeDislike("dislike")}
              >
                {dislikeStatus ? <AiFillDislike /> : <AiOutlineDislike />}
              </button>
            </div>
          </div>

          <div className="separator"></div>
          <DivComment1 postId={post.post_id} />
        </div>
      </div>
    </div>
  );
};

export default ModalPostMap_View;

// /* 레거시 파일입니다~~~!!!
//  * 작성자: 박준우
//  * 작성일: 240829
//  * 설명: 지도 게시글 조회 및 수정 팝업
//   - 240830 수정
//     경로 파일 수정 막음(장점보다 단점이 더 많았기 때문)
//  * 부모 연결: Home.js
//  */
//     import "../../asset/css/component/ModalPostMap_View.css"; // Modal 컴포넌트용 CSS

//     import React, { useState, useEffect } from "react";
//     import { useNavigate } from "react-router-dom";
//     import axios from "axios";
//     import { format } from "date-fns";
//     import { useAuth } from "../../asset/util/authContext.js";
//     import { DivOlMap } from "../tool/olVworldMap.js"; // 맵 툴
//     import DivComment1 from "../component/DivComment1.js"
//     import FileList from "../tool/fileList.js";
//     import manageBoard from "../../asset/util/manageBoard.js";
//     import manageFile from "../../asset/util/manageFile.js";

//     export const ModalPostMap_View = ({ isOpen, onClose, post, files, map }) => {
//       const navigate = useNavigate();
//       const { isSign, userId, userLevel } = useAuth(); // 사용자 상태 가져오기

//       useEffect(() => {
//         if (isOpen) {
//           getFile();
//         }
//       }, [isOpen]);

//       const getFile = async () => {
//         try {
//           if (post.post_file) {
//             const condition = { post_file: post.post_file };
//             const result = await manageFile({
//               type: "copyToTemp",
//               data: condition,
//             });
//           }
//         } catch (error) {
//           console.error("파일 가져오기 실패:", error);
//         }
//       };

//       const handleModalClose = () => {
//         try {
//           if (files.length > 0) {
//             const data = files.map((file) => ({
//               file_id: file.file_id,
//               post_file: file.post_file,
//               file_path: file.file_path,
//             }));
//             manageFile({
//               type: "copyToOfficial",
//               data: data,
//             });
//           }
//           onClose();
//         } catch (error) {
//           console.error("파일 처리 오류:", error);
//         }
//       };

//       const handlePostUpdate = (post_id, user_id, newTitle, newContent) => {
//         const data = {
//           category: 1,
//           post_id: post_id,
//           title: newTitle,
//           content: newContent,
//         };
//         if (user_id) {
//           data.user_id = user_id;
//         }
//         manageBoard({
//           type: "update",
//           data: data,
//         });
//       };

//       const handlePostDelete = (post_id, user_id) => {
//         if (isSign) {
//           if (user_id === userId || userLevel < 5) {
//             try {
//               const data = {
//                 category: 1,
//                 user_id: user_id,
//                 post_id: post_id,
//               };
//               manageBoard({
//                 type: "delete",
//                 data: data,
//               });
//               deleteFile();
//               navigate('/');
//               window.location.reload();
//             } catch (err) {
//               console.error("ModalPostMap_View ::: 삭제실패 : ", err);
//             }
//           } else {
//             alert("본인이 쓴 글만 삭제가 가능합니다.");
//           }
//         } else {
//           alert("로그인 후 이용하세요.");
//           return;
//         }
//       };

//       const deleteFile = () => {
//         if (files.length > 0) {
//           Promise.all(
//             files.map((fileItem) => {
//               const data = {
//                 file_path: fileItem.file_path,
//                 file_id: fileItem.file_id,
//               };
//               axios.delete("/file", { params: data });
//             })
//           )
//             .then((response) => {
//               console.log("MPM_View ::: deleteFile 성공:", response);
//             })
//             .catch((error) => {
//               console.error("MPM_View ::: deleteFile 실패:", error);
//             });
//         }
//       };

//       if (!isOpen) {
//         return null;
//       }

//       return (
//         <div className="ModalPost2">
//           <EditablePostItem
//             key={post.post_id}
//             post={post}
//             fileList={files}
//             mapFile={map}
//             onClose={handleModalClose}
//             onUpdate={handlePostUpdate}
//             onDelete={handlePostDelete}
//           />
//         </div>
//       );
//     };

//     const EditablePostItem = ({
//       post,
//       fileList,
//       mapFile,
//       onClose,
//       onUpdate,
//       onDelete,
//     }) => {
//       const { isSign, userId, userLevel } = useAuth(); // 사용자 조회
//       const [isEdit, setEdit] = useState(false);
//       const handleEdit = () => {
//         if(isSign) {
//           if (post.user_id === userId || userLevel < 5) {
//             setEdit(true);
//           } else {
//             alert("본인이 쓴 글만 수정이 가능합니다.");
//           }
//         } else {
//           alert("로그인 후 이용하세요.");
//           return;
//         }
//       };
//       const handleUpdate = () => {
//         const title = document.getElementById("postTitle").value;
//         const content = document.getElementById("postContent").value;
//         onUpdate(post.post_id, post.user_id, title, content);
//         setEdit(false); // 수정 모드 종료
//       };

//       const copyLinkToClipboard = () => {
//         const url = window.location.href;
//         navigator.clipboard
//           .writeText(url)
//           .then(() => {
//             alert("링크가 복사되었습니다!");
//           })
//           .catch((err) => {
//             console.error("링크 복사 실패:", err);
//           });
//       };

//       return (
//         <div className="ModalPostMap_View">
//           <div className="modal__content">
//             <div className="modal__left">
//               <div className="modal__header">
//                 <DivOlMap file={mapFile} />
//               </div>
//               <div className="modal__body">
//                 <FileList files={fileList} />
//                 <div className="link-copy__section">
//                   <ul className="link-copy__items">
//                     <li className="link-copy__item">
//                       <input
//                         type="text"
//                         value={window.location.href} // 현재 페이지 URL
//                         readOnly
//                         className="link-copy__input"
//                       />
//                       <button
//                         className="link-copy__button"
//                         onClick={copyLinkToClipboard}
//                       >
//                         링크 복사
//                       </button>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             <div className="modal__right">
//               <button className="modal__close" onClick={onClose}>
//                 &times;
//               </button>
//               {isEdit ? (
//                 <div className="modal__board">
//                   <input
//                     type="text"
//                     id="postTitle"
//                     defaultValue={post.title}
//                     className="post__title-input"
//                   />
//                   <textarea
//                     id="postContent"
//                     defaultValue={post.content}
//                     className="post__content-input"
//                   />
//                 </div>
//               ) : (
//                 <div className="modal__board">
//                   <div className="post__title">{post.title}</div>
//                   <div className="post__content">{post.content}</div>
//                 </div>
//               )}
//               <div className="modal__footer">
//                 <span className="post__author">
//                   작성자: {post.user_id || '익명'}
//                 </span>
//                 <span className="post__date">
//                   등록일: {format(new Date(post.created_at), 'yyyy-MM-dd')}
//                 </span>
//                 <span className="post__date">
//                   수정일: {format(new Date(post.updated_at), 'yyyy-MM-dd')}
//                 </span>
//                 {isSign && (post.user_id === userId || userLevel < 5) && (
//                   <div className="post__actions">
//                     {isEdit ? (
//                       <button
//                         id="btnSave"
//                         className="btn btn--save"
//                         onClick={handleUpdate}
//                       >
//                         저장
//                       </button>
//                     ) : (
//                       <>
//                         <button
//                           id="btnUpdate"
//                           className="btn btn--update"
//                           onClick={handleEdit}
//                         >
//                           수정
//                         </button>
//                         <button
//                           id="btnDelete"
//                           className="btn btn--delete"
//                           onClick={() => onDelete(post.post_id, post.user_id)}
//                         >
//                           삭제
//                         </button>
//                       </>
//                     )}
//                   </div>
//                 )}
//               </div>

//               <div className="separator"></div>
//               <DivComment1 postId={post.post_id} />
//             </div>
//           </div>
//         </div>
//       );
//     };
