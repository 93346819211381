/*
 * 작성자: 박준우
 * 작성일: 240804 (240817 수정)
 * 설명: 
  - 240817 수정 내용
    로그인, 로그아웃 modal 방식으로 변경
    className BEM 적용
    AuthProvider를 사용하여 전역 상태 관리

 * 부모 연결: index.js
*/
import "./asset/css/App.css"; // baselayer css
import "./asset/css/page/ModalSign.css"; // Popup:: Signin css

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "./asset/util/useModal";
import { AuthProvider } from "./asset/util/authContext";
import { AppRoutes } from "./asset/routes/appRoutes";
import { hashingKey } from "./asset/util/generateKey";

import SideBarKorea from "./layout/page/SideBarKorea";
import { ModalSignIn } from "./layout/page/ModalSignIn";
import { ModalSignUp } from "./layout/page/ModalSignUp";

function App() {
  const navigate = useNavigate();

  const signinModal = useModal();
  const signupModal = useModal();
  // const mypageModal = useModal();

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleMove = () => {
    signinModal.closeModal();
    signupModal.openModal();
  };

  const handleMove2 = () => {
    signupModal.closeModal();
    signinModal.openModal();
  };

  /* MyPage 들어가는건 별다른 처리 없이 바로 진입하도록 처리 */
  /* 241115 레거시 - 박준우 */
  // const handleSignCheck = async (isResult, id) => {
  //   if (isResult) {
  //     mypageModal.closeModal();
  //     const key = await hashingKey(id); // id 받는 거 기다렸다가 넘겨야함
  //     navigate(`/Mypage?key=${key}`);
  //   } else {
  //     alert("비밀번호 오류");
  //   }
  // };

  return (
    <AuthProvider>
      <div className="App">
        <SideBarKorea
          toggleSidebar={toggleSidebar} // 토글 버튼 onClick
          handleSignin={signinModal.openModal} // 로그인 onClick
          handleSignup={signupModal.openModal} // 회원가입 onClick
          handleMypage={() => navigate("/Mypage")} // 마이페이지 onClick
          // handleMypage={mypageModal.openModal}
        />

        <main
          className={`app__main-content ${isSidebarOpen ? "" : "full-width"}`}
        >
          <AppRoutes />
        </main>

        <ModalSignIn
          isOpen={signinModal.isModalOpen}
          onClose={signinModal.reloadModal}
          onMove={handleMove}
        />
        <ModalSignUp
          isOpen={signupModal.isModalOpen}
          onClose={signupModal.closeModal}
          onMove={handleMove2}
        />
        {/* <ModalSignCheck
          isOpen={mypageModal.isModalOpen}
          onClose={mypageModal.closeModal}
          onCheck={handleSignCheck}
        /> */}
      </div>
    </AuthProvider>
  );
}

export default App;
