/*
 * 작성자: 박준우
 * 작성일: 240819
 * 설명: 지도 게시글 추가 팝업
  - 240819 수정
    manageBoard와 통합을 시도했으나 포기 
    (파일 이동도 불안정하고, 비동기 과정에서 오류가 많이 발생함)
 */
import "../../asset/css/component/ModalPostMap_Add.css"; // Modal 컴포넌트용 CSS

import React, { useState, useEffect } from "react";
import axios from "axios";
import manageBoard from "../../asset/util/manageBoard.js";
import manageFile from "../../asset/util/manageFile.js";
import { DivOlminiMap } from "../tool/olVworldMap.js"; // 맵 툴
import { DragAndDrop } from "../tool/dragAndDrop.js"; // 드래그앤드롭 툴
import { useAuth } from "../../asset/util/authContext.js";
import { uniqueKey } from "../../asset/util/generateKey.js";

export const ModalPostMap_Add = ({ isOpen, onClose }) => {
  const { userId } = useAuth();
  const [file, setFile] = useState([]); // 현재 선택된 파일 정보 목록
  const [isUploading, setIsUploading] = useState(false);
  const maxFiles = 1; // 허용할 파일 개수
  const allowedExtensions = ["gpx", "tcx", "kml"]; // 허용할 확장자

  const [selectedMap, setSelectedMap] = useState([]); // 선택된 지도

  const handleFilesUpload = (newFile) => {
    setFile(newFile); // DragAndDrop에서 받은 파일 정보 목록 설정
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isOpen, isUploading]);

  useEffect(() => {
    const mapFiles = async () => {
      if (file.length > 0) {
        const a = await getMapFile(file);
        setSelectedMap(a);
      } else {
        setSelectedMap([]);
      }
    };

    mapFiles();
  }, [file]);

  const getMapFile = async (fileItem) => {
    try {
      const dotIndex = fileItem[0].filename.lastIndexOf(".");
      const nameWithoutExtension = fileItem[0].filename.substring(0, dotIndex);
      const response = await axios.get("/file/download", {
        params: {
          fileName: nameWithoutExtension + ".geojson",
          type: "geojson",
        },
        responseType: "blob",
      });
      const file = new File([response.data], fileItem[0].originalname);
      return file;
    } catch (error) {
      console.error("파일 다운로드 오류:", error);
      return null;
    }
  };

  const handleBeforeUnload = (event) => {
    if (isUploading) return;

    if (
      document.getElementById("postTitle").value !== "" ||
      document.getElementById("postContent").value !== "" ||
      file.length > 0
    ) {
      event.preventDefault();
      event.returnValue = "";

      setFile([]);
      deleteTempFile();
    }
  };

  const handleClose = () => {
    if (
      document.getElementById("postTitle").value !== "" ||
      document.getElementById("postContent").value !== "" ||
      file.length > 0
    ) {
      const chkClose = window.confirm(
        "정말로 창을 닫으시겠습니까? 작성 중인 내용이 사라질 수 있습니다."
      );

      if (chkClose) {
        deleteTempFile();
        onClose(); // 창을 닫는 함수 호출
      }
    } else {
      deleteTempFile();
      onClose();
    }
  };

  const handleUpload = async () => {
    setIsUploading(true);

    const key = uniqueKey();
    const title = document.getElementById("postTitle").value;
    const content = document.getElementById("postContent").value;

    if (title === "" || content === "") {
      alert("제목과 내용을 모두 입력해 주세요.");
      setIsUploading(false);
      return;
    }

    const data = {
      category: 1,
      category_detail: "all",
      title: title,
      content: content,
      post_file: key,
      user_id: userId,
    };

    const fileInfo = file.map((file) => ({
      file_name: file.originalname,
      file_extension: file.originalname.split(".").pop().toLowerCase(),
      file_path: file.filename,
      file_size: file.size,
      file_mimetype: file.mimetype,
      post_file: key,
    }));

    try {
      await manageBoard({
        type: "insert",
        data: data,
      });

      await manageFile({
        type: "moveToOfficial",
        data: fileInfo,
      });

      alert("저장이 완료되었습니다.");
      // 저장 후 페이지를 새로고침하지 않고, 입력한 데이터를 초기화
      setFile([]);
      document.getElementById("postTitle").value = "";
      document.getElementById("postContent").value = "";

      // 페이지 새로고침 (필요한 경우)
      window.location.reload();
    } catch (error) {
      console.error("저장 중 오류 발생:", error);
      alert("저장 중 오류가 발생했습니다. 다시 시도해 주세요.");
      setIsUploading(false);
    }
  };

  const deleteTempFile = () => {
    Promise.all(
      file.map((fileItem) => {
        const data = { fileName: fileItem.filename };
        return axios.delete("/file", { params: data });
      })
    )
      .then((responses) => {
        console.log("MPM_Add ::: deleteTempFile 성공:", responses);
        setFile([]);
        setSelectedMap([]);
      })
      .catch((error) => {
        console.error("MPM_Add ::: deleteTempFile 실패:", error);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="ModalPostMap_Add">
      <div className="modal-post__content">
        <div className="modal-post__header">
          <DivOlminiMap file={selectedMap} />
          <button className="modal-post__close-button" onClick={handleClose}>
            &times;
          </button>
        </div>
        <div className="modal-post__body">
          <div className="modal-post__file-upload">
            <DragAndDrop
              onFilesUpload={handleFilesUpload}
              maxFiles={maxFiles}
              allowedExtensions={allowedExtensions}
            />
          </div>
          <input
            type="text"
            id="postTitle" // ID를 부여하여 저장 시 DOM에서 값 읽기
            className="modal-post__title-input"
          />
          <textarea
            id="postContent" // ID를 부여하여 저장 시 DOM에서 값 읽기
            className="modal-post__content-input"
          />
        </div>
        <div className="modal-post__footer">
          <div className="modal-post__actions">
            <button className="modal-post__button-save" onClick={handleUpload}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
