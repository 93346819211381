/*
 * 작성자: 박준우
 * 작성일: 240810
 * 설명: 
 * JWT토큰 전달,갱신방식
    전달방식 - httpOnly 토큰 전달
    갱신방식 - Access 토큰이 만료되면 Refresh 토큰을 요청에 같이 전달. 응답으로 갱신된 토큰 받았음.
 * 요청 전 interceptors
    access토큰 있으면 JWT 디코딩해서 만료시간 검사
 * 응답 전 interceptors
    access 토큰 만료돼서 응답헤더에 새로운 access토큰이 받아지면
    만료된 access토큰 새 토큰으로 교체
    이후 요청 헤더에 새 access토큰을 담기


 * 200이 성공시그널 입니다.

 * jsonwebtoken은 Buffer, crypto 등의 Node.js 모듈을 사용하기 때문에 Webpack 5 환경에서 추가적인 설정이 필요합니다.  
 * 인증이 필요한 모든 axios에 해당 파일 선언후 실행할 것
*/

import axios from "axios";
import authToken from "./authToken";

// 새로운 Axios 인스턴스 생성
const authInterceptor = axios.create({
  timeout: 1000,
  withCredentials: true, // HttpOnly 쿠키 자동 전송
});

// 요청 인터셉터 - accessToken 만료시 갱신
authInterceptor.interceptors.request.use(
  async (config) => {
    try {
      // accessToken 유효성 검사
      const isAccessValid = await authToken.accessVerify();
      console.log("authInterceptor ::: isAccessValid", isAccessValid);

      if (isAccessValid.data.ok === false) {
        // accessToken이 만료된 경우 refreshToken으로 재발급 시도
        const newAccessToken = await authToken.getNewAccessToken();
        console.log("authInterceptor ::: newAccessToken", newAccessToken);

        if (newAccessToken) {
          console.log("authInterceptor ::: AccessToken 갱신 완료");
        } else {
          console.log("로그인 실패 or 로그인 상태 x");
          return Promise.reject(new Error("Failed to refresh token"));
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return config;
  },
  (error) => {
    handleError(error);
    return Promise.reject(error);
  }
);

// 응답 인터셉터 - 에러 핸들링 및 로그 출력
authInterceptor.interceptors.response.use(
  async (response) => {
    // 만약 응답 헤더에서 새로운 accessToken이 존재하면
    return response;
  },
  (error) => {
    handleError(error);
    return Promise.reject(error);
  }
);

// 공통 에러 처리 함수
const handleError = (error) => {
  if (error.response) {
    console.error("응답 오류 발생:", error.response.data);
  } else {
    console.error("요청 오류 발생:", error.message);
  }
};

export default authInterceptor;
