/*
 * 작성자: 박준우
 * 작성일: 240814
 * 설명: 게시글 생성(통신) 관련 util (대댓글은 이걸로 하는거 아님)
 * 부모 연결:
 * 
 * 호출 예제:
  import manageBoard from "../../asset/util/manageBoard"; (page 경로 기준)
  try {
    const data = {
      user_id = user_id?user_id:"",
      title = title,
      content = content,
    }
    manageBoard({
      type: select(조회), insert(삽입), update(수정), delete(삭제) 중 1개
      data: data
    })
  } catch (err) {
  }
 */
import axios from "axios";

// GET 요청 처리 함수
const selectBoard = async (data) => {
  try {
    const response = await axios.get("/board", { params: data });
    return response.data;
  } catch (error) {
    console.error("manageBoard GET Error:", error);
    throw error;
  }
};

// POST 요청 처리 함수
const insertBoard = async (data) => {
  try {
    const response = await axios.post("/board", data);
    return response.data;
  } catch (error) {
    console.error("manageBoard POST Error:", error);
    throw error;
  }
};

// PUT 요청 처리 함수
const updateBoard = async (data) => {
  try {
    const response = await axios.put("/board", data);
    return response.data;
  } catch (error) {
    console.error("manageBoard PUT Error:", error);
    throw error;
  }
};

// DELETE 요청 처리 함수
const deleteBoard = async (data) => {
  try {
    const response = await axios.delete("/board", { params: data });
    return response.data;
  } catch (error) {
    console.error("manageBoard DELETE Error:", error);
    throw error;
  }
};

// manageBoard 객체에 함수들을 추가
const manageBoard = async ({ type, data }) => {
  try {
    if (type === "select") {
      return await selectBoard(data);
    } else if (type === "insert") {
      return await insertBoard(data);
    } else if (type === "update") {
      return await updateBoard(data);
    } else if (type === "delete") {
      return await deleteBoard(data);
    } else {
      throw new Error("잘못된 요청 유형입니다.");
    }
  } catch (error) {
    console.error("manageDB Error:", error);
    throw error;
  }
};

// export default로 manageBoard 객체를 내보내기
export default manageBoard;
