import axios from "axios";

const moveToTemp = (data) => {
  return axios
    .post("file/manage/move2temp", data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("File POST Error:", error);
      return null;  // 에러 발생 시 null 반환
    });
};

const moveToOfficial = (data) => {
  return axios
    .post("file/manage/move2official", data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("File POST Error:", error);
      return null;  // 에러 발생 시 null 반환
    });
};

const copyToTemp = (data) => {
  return axios
    .post("file/manage/copy2temp", data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("File POST Error:", error);
      return null;  // 에러 발생 시 null 반환
    });
};

const copyToOfficial = (data) => {
  return axios
    .post("file/manage/copy2official", data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("File POST Error:", error);
      return null;  // 에러 발생 시 null 반환
    });
};

//파일 데이터 db 조회
const getDB = (data) => {
  return axios
    .get("file", { params: data })
    .then((response) => response.data)
    .catch((error) => {
      console.error("File GET Error:", error);
      return null;  // 에러 발생 시 null 반환
    });
}

// manageFile 객체에 함수들을 추가
const manageFile = async({ type, data }) => {
  if (data && Array.isArray(data)) {
    // 배열의 각 항목에 대해 비동기 작업을 처리
    await Promise.all(data.map(async (item) => {
      if (type === "moveToTemp") {
        return moveToTemp(item);
      }
      if (type === "moveToOfficial") {
        return moveToOfficial(item);
      }
      if (type === "copyToTemp") {
        return copyToTemp(item);
      }
      if (type === "copyToOfficial") {
        return copyToOfficial(item);
      }
    }));
  } else if(data) {
    if (type === "moveToTemp") {
      return moveToTemp(data);
    }
    if (type === "moveToOfficial") {
      return moveToOfficial(data);
    }
    if (type === "copyToTemp") {
      return copyToTemp(data);
    }
    if (type === "copyToOfficial") {
      return copyToOfficial(data);
    }
  }

  if( type === "getDB") {
    return getDB(data);
  }
};

// export default로 manageFile 객체를 내보내기
export default manageFile;
