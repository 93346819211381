/*
 * 작성자:
 * 작성일:
 * 설명: 지금 겪고 계신 문제는 Axios 요청 시 사용한 URL 경로가 상대 경로로 설정되어 있기 때문
 *       현재 코드에서는 "manage"로 요청하고 있는데,
 *       이 경우 브라우저의 현재 경로를 기준으로 상대적인 URL이 됨
 *       따라서 컴포넌트가 /Manager 경로에서 렌더링되고 있다면,
 *       "manage"는 /Manager/manage로 해석됩니다.
 *       Axios 요청 시 URL을 절대 경로로 변경하여 문제를 해결할 수 있습니다.
 *       절대 경로는 슬래시(/)로 시작하므로, Axios 요청의 URL을 "/manage"로 수정
 * 부모 연결:
 * 자식 연결:
 */
import axios from "axios";

// GET 요청 처리 함수
const selectDB = async (data) => {
  try {
    const response = await axios.get("/manage", { params: data });
    return response.data;
  } catch (error) {
    console.error("manageDB GET Error:", error);
    throw error;
  }
};

// POST 요청 처리 함수
const insertDB = async (data) => {
  try {
    const response = await axios.post("/manage", data);
    return response.data;
  } catch (error) {
    console.error("manageDB POST Error:", error);
    throw error;
  }
};

// PUT 요청 처리 함수
const updateDB = async (data) => {
  try {
    const response = await axios.put("/manage", data);
    return response.data;
  } catch (error) {
    console.error("manageDB PUT Error:", error);
    throw error;
  }
};

// DELETE 요청 처리 함수
const deleteDB = async (data) => {
  try {
    const response = await axios.delete("/manage", { params: data });
    return response.data;
  } catch (error) {
    console.error("manageDB DELETE Error:", error);
    throw error;
  }
};

// manageDB 객체에 함수들을 추가
const manageDB = async ({ type, data }) => {
  try {
    if (type === "select") {
      return await selectDB(data);
    } else if (type === "insert") {
      return await insertDB(data);
    } else if (type === "update") {
      return await updateDB(data);
    } else if (type === "delete") {
      return await deleteDB(data);
    } else {
      throw new Error("잘못된 요청 유형입니다.");
    }
  } catch (error) {
    console.error("manageDB Error:", error);
    throw error;
  }
};

// export default로 manageDB 객체를 내보내기
export default manageDB;
