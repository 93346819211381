/*
 * 작성자: 
 * 작성일: 
 * 설명: 
 * 수정된 코드에서는 handleSignup 함수가 form의 onSubmit 이벤트 핸들러로 설정되었으므로, 
 * handleSignup을 직접 호출할 필요 없이 폼을 제출하면 handleSignup이 자동으로 호출됩니다. 
 * form의 제출이 handleSignup을 호출하는 방식은 더 표준적이고 사용자 경험에도 적합합니다.
다음은 수정된 부분을 설명합니다:
폼 제출 시 handleSignup 호출
폼의 onSubmit 이벤트 핸들러로 handleSignup을 설정하면, 
사용자가 폼을 제출할 때 자동으로 handleSignup이 호출됩니다. 
이를 위해 버튼은 type="submit"으로 설정하고, 폼은 onSubmit={handleSignup}을 설정합니다. 
... 이었으나 formik과 yup 모듈 사ㅇㅇ으로 callback hook 포기

useCallback으로 한번만 호출하게끔 해야 response값 누락이 발생하지 않고 동작함
 * 부모 연결: 
 * 자식 연결: 
*/

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik"; //로그인 폼 상태 관리
import * as Yup from "yup"; //로그인 유효성 검사
import axios from "axios";

export const ModalSignUp = ({ isOpen, onClose, onMove }) => {
  if (!isOpen) return null;

  const validSignup = Yup.object({
    user_id: Yup.string()
      .min(6, "아이디는 6자 이상이어야 합니다.")
      .matches(
        /^[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/,
        "아이디는 영어, 숫자, 특수문자만 포함할 수 있습니다."
      )
      .required("아이디를 입력해주세요."),
    user_pw: Yup.string()
      .matches(/[A-Za-z]/, "비밀번호는 영어를 포함해야 합니다.")
      .matches(/\d/, "비밀번호는 숫자를 포함해야 합니다.")
      .matches(
        /^[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/,
        "비밀번호는 영어, 숫자, 특수문자만 포함할 수 있습니다."
      )
      .required("비밀번호를 입력해주세요."),
    user_pw2: Yup.string().oneOf(
      [Yup.ref("user_pw"), null],
      "비밀번호가 일치하지 않습니다."
    ),
  });

  const handleSignup = (values) => {
    // 일반 로그인 권한은 모두 11 부여 (0~10에서 차등으로 추가 권한 부여)
    const userData = {
      user_id: values.user_id,
      user_pw: values.user_pw,
      user_pw2: values.user_pw2,
      user_level: 11,
    };
    axios
      .post("/signup", userData)
      .then((res) => {
        alert(res.data.isSuccess);
        if (res.data.isSuccess) {
          onClose();
        }
      })
      .catch((err) => {
        alert("ERROR: ", err);
      });
  };

  return (
    <div className="ModalSign">
      <div className="modal-sign__content">
        <div className="modal-sign__top">
          <h2 className="modal-sign__title">회원가입</h2>
          <button className="modal-sign__close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-sign__middle">
          <Formik
            initialValues={{ user_id: "", user_pw: "", user_pw2: "" }}
            validationSchema={validSignup}
            onSubmit={handleSignup}
          >
            <Form className="modal-sign__form">
              <Field
                className="modal-sign__input"
                type="text"
                placeholder="아이디"
                name="user_id"
              />
              <ErrorMessage
                className="modal-sign__error-msg"
                name="user_id"
                component="div"
              />

              <Field
                className="modal-sign__input"
                type="password"
                placeholder="비밀번호"
                name="user_pw"
              />
              <ErrorMessage
                className="modal-sign__error-msg"
                name="user_pw"
                component="div"
              />

              <Field
                className="modal-sign__input"
                type="password"
                placeholder="비밀번호 확인"
                name="user_pw2"
              />
              <ErrorMessage
                className="modal-sign__error-msg"
                name="user_pw2"
                component="div"
              />

              <button className="modal-sign__btn" type="submit">
                회원가입
              </button>
            </Form>
          </Formik>
        </div>
        <div className="modal-sign__bottom">
          <div className="modal-sign__sign">
            <p className="modal-sign__text">이미 계정이 있으신가요? </p>
            <p className="modal-sign__move" onClick={onMove}>
              로그인
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
