import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "../../layout/page/Home";
import Inquire from "../../layout/page/Inquire";
import InquireReqCourse from "../../layout/page/InquireReqCourse";
import ManageAccount from "../../layout/page/ManageAccount";
import ManagePost from "../../layout/page/ManagePost";
import MyPage from "../../layout/page/MyPage";
import NotFound from "../../NotFound";
import { PrivateRoute } from "./privateRoute";
import { useAuth } from "../util/authContext";

// 새 탭 리다이렉트를 위한 컴포넌트
function RedirectToPaaran() {
  const navigate = useNavigate(); // 페이지 이동 용도

  useEffect(() => {
    window.open("https://paaran.com", "_blank"); // 새 탭으로 paaran.com 열기
    navigate("/");
  }, []);

  return null; // 렌더링할 내용 없음
}

export function AppRoutes() {
  const { isSign, userLevel } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Inquire" element={<Inquire />} />
      <Route path="/Inquire/reqCourse" element={<InquireReqCourse />} />
      {/* /develop 경로에 새 탭 리다이렉트 추가 */}
      <Route path="/Developer" element={<RedirectToPaaran />} />
      <Route
        element={
          <PrivateRoute
            isSign={isSign}
            requiredLevel={5}
            userLevel={userLevel}
          />
        }
      >
        <Route path="/Manager/account" element={<ManageAccount />} />
        <Route path="/Manager/post" element={<ManagePost />} />
        {/* <Route path="/Manager/file" element={<Developer />} />
        <Route path="/Manager/error" element={<Developer />} /> */}
      </Route>
      <Route element={<PrivateRoute isSign={isSign} />}>
        <Route path="/MyPage" element={<MyPage />} />
      </Route>
      <Route path="*" element={<NotFound />} /> {/* 맨 마지막 고정 */}
    </Routes>
  );
}
